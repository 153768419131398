import React, { useEffect, useState } from "react";
import axiosInstance, {
  BASE_URL,
  config,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  authKey,
  localStorageAuthUserData,
} from "../../../../admin/helper/AuthUserData";
import {
  COURSE_LIST,
  INSTRUCTOR_CERTIFICATION_CREATE,
  INSTRUCTOR_CERTIFICATION_UPDATE,
  INSTRUCTOR_COURSES_LIST,
  INSTRUCTOR_STUDNETS_LIST,
  USERS_STUDENT_LIST,
} from "../../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../../admin/helper/ToastMessage";
import Lorder from "../../../../admin/helper/Lorder";
import { formatDateTwoType } from "../../../../admin/helper/dateFormate";

const CertificatEditFrom = ({ show, hide, afterApiRespApiCall }) => {
  const { editData, certificateId } = show || {};
  console.log(
    show,
    hide,
    afterApiRespApiCall,
    "show, hide, afterApiRespApiCallshow, hide, afterApiRespApiCall"
  );
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const [certificateContent, setCertificateContent] = useState({
    dateCertificate: new Date().toISOString().split("T")[0],
    title: "Certificate of Completion",
    approval: "EMS BOARD APPROVAL #1194",
    university: "University Hospitals Bedford Medical Center",
    certifiesTitle:
      "Certifies the named individual has satisfactorily completed the specific course and is awarded the number of CE hours shown below.",
    name: "",
    date: "July 2024",
    course: "Summer Emergencies",
    training: "TXA Training",
    position: "",
    hours: "Total of 2.00 Hours Continued Education",
    additionalInfo:
      "General 0.00 hrs | Trauma hrs | Geriatric hrs | Pediatric hrs | Cardiac hrs",
    totalCE: "Total CE Hours Awarded 2.00",
    verifyDate: `${new Date().toISOString().split("T")[0]}`,
    directorSignature: "",
    director: "Dr. Donald Spaner",
    directorTitle: "Program Medical Director",
    coordinatorSignature: "",
    coordinator: "Daniel Ellenberger",
    coordinatorTitle: "Program Coordinator",
  });
  const onChangeHndle = (e) => {
    const { name, value } = e.target;
    setCertificateContent({ ...certificateContent, [name]: value });
  };

  const [data, setData] = useState([]);
  const [updateLoader, serUpdateLoader] = useState(false);

  const studentGetListApi = async () => {
    // setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        mainRole === authKey?.instructor
          ? `${BASE_URL}${INSTRUCTOR_STUDNETS_LIST}${mainId}`
          : `${BASE_URL}${USERS_STUDENT_LIST}?limit=${900}`
      );
      if (response.status) {
        const studentGetData = response?.data?.detail;
        const convertData = studentGetData?.map((instruct) => {
          const {
            _id,
            role,
            phone,
            fullname,
            expiry_date,
            email,
            certification_number,
            agency,
            address,
            is_verify,
            profilepic,
          } = instruct;
          return {
            _id,
            role,
            agency,
            address,
            name: fullname,
            certificationNumber: certification_number,
            expirationDate: expiry_date,
            email: email,
            phoneNumber: phone,
            status: is_verify ? "verify" : "Not verify",
            profilepic: profilepic,
          };
        });

        setData(convertData);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    // setTableLoading(false);
  };

  /// course api

  const [dataCourse, setDataCourse] = useState([]);
  const courseManagementGetListApi = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}${COURSE_LIST}`);
      if (response.status) {
        setDataCourse(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };
  // course api

  const {
    dateCertificate,
    title,
    approval,
    university,
    certifiesTitle,
    date,
    training,
    hours,
    additionalInfo,
    totalCE,
    directorSignature,
    director,
    directorTitle,
    coordinatorSignature,
    coordinator,
    coordinatorTitle,
    verifyDate,
  } = certificateContent || {};
  const parsePositionName = (position) => {
    try {
      // Check if the position is a valid JSON string
      const parsed = JSON.parse(position);
      // If it parses successfully, return a useful property, e.g., the name of the position
      if (parsed && parsed) {
        return parsed;
      }
    } catch (error) {
      // If the JSON parsing fails, just return the position as is
      return position;
    }
  };
  const parsePositionPosition = (position) => {
    try {
      // Check if the position is a valid JSON string
      const parsed = JSON.parse(position);
      // If it parses successfully, return a useful property, e.g., the name of the position
      if (parsed && parsed) {
        return parsed;
      }
    } catch (error) {
      // If the JSON parsing fails, just return the position as is
      return position;
    }
  };

  const convertToStudent =
    certificateContent?.name !== ""
      ? parsePositionName(certificateContent?.name)
      : [];

  const convertToCourse =
    certificateContent?.position !== ""
      ? parsePositionPosition(certificateContent?.position)
      : [];

  console.log(certificateContent, "certificateContentcertificateContent");

  const onSubmitHndle = async () => {
    if (certificateContent?.name !== "") {
      if (certificateContent?.position !== "") {
        if (certificateContent?.directorSignature !== "") {
          if (certificateContent?.coordinatorSignature !== "") {
            serUpdateLoader(true);
            let formData = new FormData();
            formData.append(
              "user_id",
              convertToStudent?._id !== undefined
                ? convertToStudent?._id
                : certificateContent?.user_id
            );
            formData.append(
              "course_id",
              convertToCourse?._id !== undefined
                ? convertToCourse?._id
                : certificateContent?.course_id
            );
            formData.append("createdBy", mainId);
            formData.append("dateCertificate", dateCertificate);
            formData.append("title", title);
            formData.append("approval", approval);
            formData.append("university", university);
            formData.append("certifiesTitle", certifiesTitle);
            formData.append(
              "name",
              convertToStudent?.name !== undefined
                ? convertToStudent?.name
                : certificateContent?.name
            );
            formData.append("date", formatDateTwoType(dateCertificate));
            formData.append(
              "course",
              convertToCourse?.name !== undefined
                ? convertToCourse?.name
                : certificateContent?.position
            );
            formData.append("training", training);
            formData.append(
              "position",
              convertToCourse?.name !== undefined
                ? convertToCourse?.name
                : certificateContent?.position
            );
            formData.append("hours", hours);
            formData.append("additionalInfo", additionalInfo);
            formData.append("totalCE", totalCE);
            formData.append("verifyDate", dateCertificate);
            formData.append("director", director);
            formData.append("directorTitle", directorTitle);
            if (
              coordinatorSignature[0] !== "h" &&
              directorSignature[0] !== "h"
            ) {
              formData.append("coordinatorSignature", coordinatorSignature[0]);
              formData.append("directorSignature", directorSignature[0]);
            }
            formData.append("coordinator", coordinator);
            formData.append("coordinatorTitle", coordinatorTitle);
            try {
              const response = await axiosInstance.put(
                `${BASE_URL}${INSTRUCTOR_CERTIFICATION_UPDATE}${certificateId}`,
                formData,
                config
              );
              if (response.status) {
                ToastHandle(response?.data?.message, "success");
                hide();
                afterApiRespApiCall();
              }
            } catch (error) {
              if (error?.response?.status === 400) {
                ToastHandle(error?.response?.data?.message, "danger");
              }
            }
            serUpdateLoader(false);
          } else {
            ToastHandle("Please upload coordinator signature image", "danger");
          }
        } else {
          ToastHandle("Please upload director signature image", "danger");
        }
      } else {
        ToastHandle("Please Select Course", "danger");
      }
    } else {
      ToastHandle("Please Select Student Name", "danger");
    }
  };
  useEffect(() => {
    studentGetListApi();
    courseManagementGetListApi();
  }, []);

  useEffect(() => {
    if (editData !== undefined) {
      setCertificateContent(editData ? editData : []);
    }
  }, [editData]);
  return (
    <div>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%] popup">
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Certificate Create
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide(false)}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="form">
                    <input
                      type="date"
                      id="dateCertificate"
                      name="dateCertificate"
                      onChange={onChangeHndle}
                      value={
                        certificateContent?.dateCertificate
                          ? certificateContent.dateCertificate.split("T")[0]
                          : ""
                      }
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />

                    <input
                      type="text"
                      id="title"
                      name="title"
                      onChange={onChangeHndle}
                      value={certificateContent?.title}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="approval"
                      name="approval"
                      onChange={onChangeHndle}
                      value={certificateContent?.approval}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="university"
                      name="university"
                      onChange={onChangeHndle}
                      value={certificateContent?.university}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="certifiesTitle"
                      name="certifiesTitleh"
                      onChange={onChangeHndle}
                      value={certificateContent?.certifiesTitle}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <select
                      name="name"
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal"
                      onChange={onChangeHndle}
                    >
                      <option value={certificateContent?.name} hidden>
                        {certificateContent?.name
                          ? certificateContent?.name
                          : "Select One Student"}
                      </option>
                      {data?.map((studentItem) => {
                        const { name } = studentItem;
                        return (
                          <option value={JSON.stringify(studentItem)}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="text"
                      id="date"
                      name="date"
                      onChange={onChangeHndle}
                      value={formatDateTwoType(
                        certificateContent?.dateCertificate
                      )}
                      placeholder=""
                      className="border border-[#D9D9D9] mb-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {/* <input
                      type="text"
                      id="course"
                      name="course"
                      onChange={onChangeHndle}
                      value={certificateContent?.course}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    /> */}
                    <input
                      type="text"
                      id="training"
                      name="training"
                      onChange={onChangeHndle}
                      value={certificateContent?.training}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <select
                      name="position"
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal"
                      value={certificateContent?.position}
                      onChange={onChangeHndle}
                    >
                      <option value="" selected hidden>
                        {certificateContent?.position
                          ? certificateContent?.position
                          : "Select One Course"}
                      </option>
                      {dataCourse?.map((courseItem) => {
                        const name = courseItem?.name;

                        return (
                          <option
                            value={JSON.stringify(courseItem)}
                            selected={
                              name === certificateContent?.position
                                ? true
                                : false
                            }
                          >
                            {name ? name : ""}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="text"
                      id="hours"
                      name="hours"
                      onChange={onChangeHndle}
                      value={certificateContent?.hours}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="additionalInfo"
                      name="additionalInfo"
                      onChange={onChangeHndle}
                      value={certificateContent?.additionalInfo}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="totalCE"
                      name="totalCE"
                      onChange={onChangeHndle}
                      value={certificateContent?.totalCE}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="verifyDate"
                      name="verifyDate"
                      onChange={onChangeHndle}
                      //   value={certificateContent?.verifyDate}
                      value={
                        certificateContent?.dateCertificate
                          ? certificateContent?.dateCertificate?.split("T")[0]
                          : ""
                      }
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="file"
                      id="directorSignature"
                      name="directorSignature"
                      onChange={(e) => {
                        setCertificateContent({
                          ...certificateContent,
                          directorSignature: e.target.files,
                        });
                      }}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="director"
                      name="director"
                      onChange={onChangeHndle}
                      value={certificateContent?.director}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="directorTitle"
                      name="directorTitle"
                      onChange={onChangeHndle}
                      value={certificateContent?.directorTitle}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="file"
                      id="coordinatorSignature"
                      name="coordinatorSignature"
                      onChange={(e) => {
                        setCertificateContent({
                          ...certificateContent,
                          coordinatorSignature: e.target.files,
                        });
                      }}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="coordinator"
                      name="coordinator"
                      onChange={onChangeHndle}
                      value={certificateContent?.coordinator}
                      placeholder=""
                      className="border border-[#D9D9D9] my-3 p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    <input
                      type="text"
                      id="coordinatorTitle"
                      name="coordinatorTitle"
                      onChange={onChangeHndle}
                      value={certificateContent?.coordinatorTitle}
                      placeholder=""
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end px-6 py-5 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onSubmitHndle()}
                  >
                    {!updateLoader ? (
                      "Update"
                    ) : (
                      <>
                        <Lorder />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default CertificatEditFrom;
