import React, { useState } from "react";
import PersonalDetailsForm from "./personalDetails/PersonalDetailsForm";
import ForgetPasswordForm from "./forgetPassword/ForgetPasswordForm";
const Index = ({setProfileUpdateData}) => {
  const personalDetails = "personalDetails";
  const forgetPassword = "forgetPassword";
  const [interFaceShow, setInterFaceShow] = useState({
    showType: personalDetails,
  });
  // const cssStyleBtn = `btn px-3 py-4 border-b-[2px] border-white text-lg hover:border-red-500 hover:text-red-500 focus:border-red-500 focus:text-red-500 border-red-500 text-red-500 `;
  return (
    <div className="border bg-white p-8">
      <div className="flex items-center gap-5 border-b mb-10">
        <button
          className={`btn px-3 py-4 border-b-[2px] border-white text-lg hover:border-red-500 hover:text-red-500 focus:border-red-500 focus:text-red-500
          ${
            interFaceShow?.showType === personalDetails
              ? "border-red-500 text-red-500"
              : ""
          }`}
          onClick={() => {
            setInterFaceShow({ showType: personalDetails });
          }}
        >
          Personal Details
        </button>{" "}
        <button
          className={`btn px-3 py-4 border-b-[2px] border-white text-lg hover:border-red-500 hover:text-red-500 focus:border-red-500 focus:text-red-500
          ${
            interFaceShow?.showType === forgetPassword
              ? "border-red-500 text-red-500"
              : ""
          }`}
          onClick={() => {
            setInterFaceShow({ showType: forgetPassword });
          }}
        >
          Update Password
        </button>
      </div>
      {interFaceShow?.showType === personalDetails && <PersonalDetailsForm  setProfileUpdateData={setProfileUpdateData}/>}
      {interFaceShow?.showType === forgetPassword && <ForgetPasswordForm />}
    </div>
  );
};

export default Index;
