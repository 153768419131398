import React from "react";
import Table from "../../../../Components/Table";
import Studentimage from "../../../../images/student.png";
import { Link, useNavigate } from "react-router-dom";
import { TableLorder } from "../../../../admin/helper/Lorder";

const StudentListTable = ({ data: certificateData, tableLorder }) => {
  const navigate = useNavigate();
  // table
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Studentimage} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification number", field: "certificationnumber" },
    { header: "Expiration date", field: "expirationdate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },

    {
      header: "Status",
      field: "status",
      render: () => (
        // <Link to="/instructor/certificate">
        <button className="btn-view text-white font-normal text-sm rounded-[5px] px-[15px] py-[10px] inline-flex justify-between items-center bg-[#01AF7B] focus:outline-0">
          Verified
        </button>
        // </Link>
      ),
    },
    {
      header: "Action",
      field: "action",
      render: () => (
        <Link to="/">
          <button className="btn-view text-white font-normal text-sm rounded-[5px] px-[15px] py-[10px] inline-flex justify-between items-center bg-[#01AF7B] focus:outline-0">
            Verify Certificate
          </button>
        </Link>
      ),
    },
  ];

  const data = [
    {
      name: "Mitchal Jackson",
      certificationnumber: "#321",
      expirationdate: "27/05/2009",
      email: "abc@gmail.com",
      phoneNumber: "9876543210",
      student: null,
    },
  ];
  // table
  return (
    <>
      {/* <Table
        columns={columns}
        data={data}
        otherAction={true}
        noAction={true}
        manageButton={true}
        iconsShow={true}
      /> */}
      <div className="table-section table-responsive rounded-[15px] w-full bg-white">
        {!tableLorder ? (
          <table className="w-full h-full table-auto table-layout-fixed">
            <thead className="bg-[#d9d9d9] h-[60px]">
              <tr>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Name</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Certification number </th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Expiration date</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Email</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Phone Number</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Course</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Status</th>
                <th scope="col" className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {certificateData?.map((certificatItem) => {
                const { user_id, course_id, verifyStatus, _id } =
                  certificatItem;
                const {
                  fullname,
                  certification_number,
                  expiry_date,
                  email,
                  phone,
                } = user_id || {};
                const { name } = course_id || {};

                return (
                  <tr className="h-[60px] border-b border-[#F4F5F9]">
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{fullname}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{certification_number}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{expiry_date}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{email}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{phone}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">{name}</td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      {verifyStatus === true ? (
                        <spna className=" text-[#01AF7B]">Verified</spna>
                      ) : (
                        <span className="text-[#e63c3c]">Not Verified</span>
                      )}
                    </td>
                    <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                      <button
                        onClick={() => {
                          navigate(`/admin/certificate/${_id}`);
                        }}
                      >
                        {verifyStatus === true ? (
                          <button className="btn-view text-white font-normal text-sm rounded-[5px] px-[15px] py-[10px] inline-flex justify-between items-center bg-[#01AF7B] focus:outline-0">
                          Verified
                        </button>
                        ) : (
                          <button className="btn-view text-white font-normal text-sm rounded-[5px] px-[15px] py-[10px] inline-flex justify-between items-center bg-[red] focus:outline-0">
                            Verify
                          </button>
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <TableLorder />
        )}
      </div>
    </>
  );
};

export default StudentListTable;
