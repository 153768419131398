import React, { useEffect, useState } from "react";
import Profileinstructor from "../../../../../images/profile-instructor.png";
import "./InstructorManagementProfile.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import Table from "../../../../../Components/Table";
import instructorimage from "../../../../../images/instructor-table.png";
import InstructorModal from "../../../../../Components/customModal/instructor-asign-Modal";
import {
  COURSE_DELETE_ASSIGN_COURSE,
  USERS_GET_INSTRUCTOR_ASSIGN_COURSE,
  USERS_GET_INSTRUCTOR_DETAIL,
} from "../../../../helper/ApiEndPoint";
import axios from "axios";
import ToastHandle from "../../../../helper/ToastMessage";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import { TableLorder } from "../../../../helper/Lorder";
const InstructorManagementProfileView = () => {
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const { id } = useParams();

  const columns = [
    {
      header: "Course Name",
      field: "cname",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <img src={instructorimage} />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Start Date", field: "sdate" },
    { header: "End Date", field: "edate" },
    { header: "Duration", field: "duration" },
    { header: "Class Days", field: "cdays" },
    { header: "Class Time", field: "ctime" },
    { header: "Time Zone", field: "timezone" },
    { header: "Location", field: "location" },
    { header: "Price", field: "price" },
  ];

  const [profileLoading, setProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const GetProfileViewApi = async (ID) => {
    setProfileLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${USERS_GET_INSTRUCTOR_DETAIL}${ID}`
      );
      if (response.status) {
        setProfileData(response?.data?.detail);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setProfileLoading(false);
  };
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState({ assignData: [], courseId: null });
  const GetAssignCourseApi = async (ID) => {
    setTableLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${USERS_GET_INSTRUCTOR_ASSIGN_COURSE}${ID}`
      );
      if (response.status) {
        const assignCourseData = response?.data?.detail;
        const convertDAta = assignCourseData?.map((assignItem) => {
          const { coursedetail, course_id } = assignItem;
          const {
            _id,
            name,
            start_date,
            end_date,
            duration,
            class_days,
            classtimeto,
            timezone,
            location,
            monthly_price,
          } = coursedetail || {};

          let courseAssignData = {
            course_id,
            _id,
            cname: name,
            sdate: start_date,
            edate: end_date,
            duration: duration,
            cdays: class_days?.map((days) => {
              return `${days}, `;
            }),
            ctime: classtimeto,
            timezone: timezone,
            location: location,
            price: monthly_price,
          };
          return courseAssignData;
        });
        setData({ assignData: convertDAta });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  // delete  api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const assignCoursesDeleteApiHndle = async (deleteId) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${COURSE_DELETE_ASSIGN_COURSE}${deleteId}`
      );
      if (response.status) {
        GetAssignCourseApi(id);
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete  api integration

  useEffect(() => {
    if (id) {
      GetProfileViewApi(id);
      GetAssignCourseApi(id);
    }
  }, [id]);
  return (
    <div className="profile-section overflow-y-auto student-profile">
      {!profileLoading ? (
        <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
          <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[20%] ">
            <div className="mb-3">
              <img
                className="text-center mx-auto"
                src={
                  profileData?.[0]?.profilepic
                    ? profileData?.[0]?.profilepic
                    : Profileinstructor
                }
              />
            </div>
            <h3 className="text-center text-xl font-semibold text-black">
              {profileData?.[0]?.fullname}
            </h3>

            <div className="social-icon">
              <ul className="flex justify-center items-center gap-2 mt-3">
                <li>
                  <a href={`tel:${profileData?.[0]?.phone}`}>
                    <IoIosCall />
                  </a>
                </li>

                <li>
                  <a href={`mailto:${profileData?.[0]?.email}`}>
                    <HiMail />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {profileData &&
            profileData?.map((profileView) => {
              const {
                address,
                fullname,
                expiry_date,
                join_date,
                is_verify,
                email,
                phone,
                certification_number,
              } = profileView;

              return (
                <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[77%] bg-white profile-info rounded-[10px] md:p-5 p-3 h-full">
                  <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      Name:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {fullname}
                    </p>
                  </div>
                  <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      Certification Number:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {certification_number}
                    </p>
                  </div>
                  <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      Expiration Date:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {expiry_date}
                    </p>
                  </div>
                  <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      Joining Date:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {join_date}
                    </p>
                  </div>
                  <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                      Phone number:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {phone}
                    </p>
                  </div>
                  <div className="flex xl:flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[20%]">
                      Address:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm  text-wrap">
                      {address}
                    </p>
                  </div>
                  <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                      E-mail:
                    </p>
                    <p className="text-black font-medium md:text-base text-sm">
                      {email}
                    </p>
                  </div>
                  <div className="flex flex-wrap sm:gap-5 gap-3">
                    <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[20%]">
                      Status:
                    </p>
                    <p
                      className={`font-regular text-sm  text-white rounded-[5px] ${
                        is_verify ? "bg-[#01AF7B]" : "bg-[red]"
                      }  py-1 px-2.5`}
                    >
                      {is_verify ? "Verify" : "Not Verify"}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <TableLorder />
      )}

      <div className="asigned-courses flex justify-between mt-10 mb-3 items-center">
        <h3 class="text-xl font-medium text-black mb-2">Assigned Courses</h3>
        <button
          onClick={() => setShowModel(true)}
          class=" bg-[#CC4D1D] text-white font-medium text-sm rounded-[5px] py-4 px-5 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
        >
          Assign Courses
        </button>
      </div>

      <Table
        columns={columns}
        data={data?.assignData}
        navigateHndle={(id) => navigate(`/course-mangement/${id}`)}
        deleteHndle={(id) => assignCoursesDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        notShowing={false}
        editButtonNotShowing={true}
        tableLoading={tableLoading}
      />
      <InstructorModal
        heading="EMS Board Management"
        show={showModel}
        hide={() => {
          setShowModel(false);
          GetAssignCourseApi(id);
        }}
        courseUserId={id}
        activeCourseUserid={data?.assignData?.map((courseid) => {
          return courseid?.course_id;
        })}
      />
    </div>
  );
};

export default InstructorManagementProfileView;
