import React from "react";

const StudentCoursesSection = ({ instructorDashboard }) => {
  const { students, courses } = instructorDashboard;
  console.log(students, courses, "instructorDashboardinstructorDashboard");
  const {
    new_students,
    passout_students,
    enrollment_students,
    total_students,
  } = students || {};
  const {
    new_courses_added,
    courses_completed,
    ongoing_courses,
    upcoming_courses,
  } = courses || {};

  return (
    <div className="dashboard-body grid md:grid-cols-2 grid-cols-1 flex lg:gap-8 gap-3">
      <div className="bg-white rounded-[15px]">
        <div className="p-3 border-b border-[#DCDCDC]">
          <p className="text-base font-medium text-black">Students</p>
        </div>
        <div className="md:py-8 py-5 px-5">
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#CC4D1D] xl:w-[55px] xl:h-[55px]  lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">N</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                New Students{" "}
              </p>
            </div>
            <div className="bg-[#cc4d1d40]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">
                {new_students}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between md:my-5 my-2 custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#B70022] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">P</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Passout Students{" "}
              </p>
            </div>
            <div className="bg-[#b7002240]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{passout_students}</p>
            </div>
          </div>
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#CC4D1D] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">E</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Enrollment Student{" "}
              </p>
            </div>
            <div className="bg-[#cc4d1d40]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{enrollment_students}</p>
            </div>
          </div>
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3 md:mt-5 mt-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#B70022] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px]  w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">T</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Total Students{" "}
              </p>
            </div>
            <div className="bg-[#b7002240]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{total_students}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-[15px]">
        <div className="p-3 border-b">
          <p className="text-base font-medium text-black">Courses</p>
        </div>
        <div className="md:py-8 py-5  px-5">
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#CC4D1D] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">N</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                New Courses Added{" "}
              </p>
            </div>
            <div className="bg-[#cc4d1d40]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{new_courses_added}</p>
            </div>
          </div>
          <div className="flex items-center justify-between md:my-5 my-3 custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#B70022] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">C</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Courses Completed{" "}
              </p>
            </div>
            <div className="bg-[#b7002240]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{courses_completed}</p>
            </div>
          </div>
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#CC4D1D] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">O</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Ongoing Courses{" "}
              </p>
            </div>
            <div className="bg-[#cc4d1d40]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{ongoing_courses}</p>
            </div>
          </div>
          <div className="flex items-center justify-between custom-shadow h-[69px] rounded-[5px] p-3 md:mt-5 mt-3">
            <div className="flex items-center xl:gap-5 gap-2">
              <div className="bg-[#B70022] lg:w-[55px] lg:h-[55px] lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] flex items-center justify-center rounded-[5px] ">
                <p className="text-white text-lg font-medium">U</p>
              </div>
              <p className="xl:text-lg text-base font-medium text-black">
                Upcoming Courses{" "}
              </p>
            </div>
            <div className="bg-[#b7002240]  rounded-[3px] flex items-center justify-center w-[40px] h-[20px]">
              <p className="text-[#CC4D1D] text-sm font-normal">{upcoming_courses}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCoursesSection;
