// import { jsPDF } from "jspdf";

// export const downloadPDF = (data) => {
//   if (!data) return;
//   const {
//     dateCertificate,
//     title,
//     approval,
//     university,
//     name,
//     date,
//     course,
//     training,
//     position,
//     hours,
//     additionalInfo,
//     totalCE,
//     verifyDate,
//     directorSignature,
//     director,
//     directorTitle,
//     coordinatorSignature,
//     coordinator,
//     coordinatorTitle
//   } = data;

//   const doc = new jsPDF();

//   doc.text(`${dateCertificate}`, 10, 10);
//   doc.text(`${title}`, 10, 20);
//   doc.text(`${approval}`, 10, 30);
//   doc.text(`${university}`, 10, 40);
//   doc.text(`${name}`, 10, 50);

//   doc.text(`${date}`, 10, 60);

//   doc.text(`${course}`, 10, 70);
//   doc.text(`${training}`, 10, 80);
//   doc.text(`${position}`, 10, 90);
//   doc.text(`${hours}`, 10, 100);
//   doc.text(`${additionalInfo}`, 10, 110);
//   doc.text(`${totalCE}`, 10, 120);
//   doc.text(`${verifyDate}`, 10, 130);
//   doc.text(`${director}`,10,140)
//   doc.text(`${directorTitle}`, 10, 150);
//   doc.text(`${coordinator}`, 10, 160);
//   doc.text(`${coordinatorTitle}`, 10, 170);
  

//   // Check if profile image URL is available and add it to the PDF
//   if (directorSignature) {
//     const img = new Image();
//     img.src = directorSignature;
//     img.onload = () => {
//       doc.addImage(img, "JPEG", 10, 180, 180, 180); // Position and size the image
//       doc.save("download.pdf"); // Save and download the PDF after the image loads
//     };
//   } else {
//     doc.save("download.pdf"); // Save and download the PDF without image
//   }
// };


import html2pdf from 'html2pdf.js';
import { formatDate } from './dateFormate';
import logo from "../../images/logo-university.png";


export const downloadPDF = (data) => {
  if (!data) return;

  const {
    dateCertificate,
    title,
    approval,
    university,
    name,
    date,
    course,
    training,
    position,
    hours,
    additionalInfo,
    totalCE,
    verifyDate,
    directorSignature,
    director,
    directorTitle,
    coordinatorSignature,
    coordinator,
    coordinatorTitle,
  } = data;

  // Set the content of the certificate HTML
  const certificateHTML = `
    <div class="main-certificate" id="capture" style="width: 210mm; height:290mm">
      <div class="bg-white  pt-4 pb-8 pl-4 pr-5 custom-shadow-cer rounded-[10px]">
        <div class="flex justify-between items-center">
          <img src="${logo}" class="max-w-[200px]" />
          <p class="text-sm border-b border-[#DCDCDC] pb-[9px] px-[10px]">
            <strong>Date :</strong> ${formatDate(dateCertificate)}
          </p>
        </div>
        <div class="certificate-detail text-center mt-[80px]">
          <h5 class="lg:text-3xl text-[#CC4D1D] font-semibold mb-4 text-3xl">
            ${title}
          </h5>
          <h2 class="text-[#333333] italic lg:text-2xl font-medium text-xl">
            ${approval}
          </h2>
          <h3 class="text-[#333333] text-xl md:mb-2 md:mt-5 my-3 font-medium">
            ${university}
          </h3>
          <p class="text-[#000] italic text-lg max-w-[736px] mx-auto mb-9">
            ${data.certifiesTitle}
          </p>
          <div class="middle-sec">
            <h2 class="italic font-semibold text-[#B70022] lg:text-2xl md:text-xl text-xl mb-[18px]">
              ${name}
            </h2>
            <div class="flex justify-center gap-4 max-w-[677.45px] mx-auto flex-wrap">
              <p class="md:text-xl text-lg text-[#000000]">
                ${date}
              </p>
              <p class="md:text-xl text-lg text-[#000000]">
                ${course}
              </p>
              <p class="md:text-xl text-lg text-[#000000]">
                ${training}
              </p>
            </div>
            <h2 class="text-black font-semibold md:text-[35px] text-xl leading-[56px] my-[19px]">
              ${position}
            </h2>
            <p class="md:text-[30px] md:leading-[48px] text-xl font-medium text-black">
              ${hours}
            </p>
            <p class="text-black md:text-xl text-lg font-mediums mt-[19px]">
              ${additionalInfo}
            </p>
            <div class="flex md:justify-between justify-center items-center mx-auto max-w-[936px] md:mt-[100px] md:mb-[50px] my-[30px] flex-wrap">
              <p class="md:text-xl text-lg leading-[45px] text-black font-medium">
                ${totalCE}
              </p>
              <p class="md:text-xl text-lg leading-[45px] text-black font-medium">
                Date: ${formatDate(verifyDate)}
              </p>
            </div>
            <div class="flex md:justify-between justify-center items-center flex-wrap gap-1">
              <div class="flex items-center justify-center flex-col">
                <img src="${directorSignature}" alt="" style="width: 150px" />
                <p class="text-black text-lg font-medium leading-[32px]">
                  ${director}
                </p>
                <p class="text-black text-lg leading-[32px]">
                  ${directorTitle}
                </p>
              </div>
              <div class="flex items-center justify-center flex-col">
                <img src="${coordinatorSignature}" alt="" style="width: 150px;" />
                <p class="text-black text-lg font-medium leading-[32px]">
                  ${coordinator}
                </p>
                <p class="text-black text-lg leading-[32px]">
                  ${coordinatorTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  // Create a temporary element for rendering the HTML
  const element = document.createElement('div');
  element.innerHTML = certificateHTML;
  document.body.appendChild(element);

  // Ensure the content with the ID 'capture' is rendered
  const captureElement = document.getElementById('capture');

  // If the element with ID 'capture' exists, generate PDF
  if (captureElement) {
    // Options for html2pdf
    const options = {
      filename: 'certificate.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2, // Ensure better image quality
        logging: true, // Useful for debugging rendering issues
        letterRendering: true,
        useCORS: true, // Enable CORS if images are from external sources
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
        margins: { top: 20, left: 20, bottom: 20 },
      },
    };

    // Use html2pdf to convert HTML content (the element with id 'capture') to a PDF and trigger download
    html2pdf().from(captureElement).set(options).save();
  }

  // Clean up by removing the temporary element
  document.body.removeChild(element);
};



