import React from "react";
import { ImSpinner9 } from "react-icons/im";

export const TableLorder = () => {
  return (
    // <div className="text-center py-5 my-5" >
    //   <div class="spinner-border" role="status">
    //     <span class="visually-hidden">Loading...</span>
    //   </div>
    // </div>
    <div className="fixed top-0 left-0 h-full w-full bg-[#0000006e] flex justify-center items-center">
          <ImSpinner9 size={65} color="white" className="animate-spin" />
        </div>
  );
};

const Lorder = () => {
  return <div>Wait...</div>;
};

export default Lorder;
