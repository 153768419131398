import React, { useEffect, useState } from "react";
import logo from "../../../../../images/logo-university.png";
import "../../../../../Components/Layout/style.css";
import { useParams } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import {
  CERTIFICATION_VERFIY_STATUS,
  INSTRUCTOR_CERTIFICATE_DETAILS,
  INSTRUCTOR_CERTIFICATION_CREATE,
} from "../../../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../../../admin/helper/ToastMessage";
import { TableLorder } from "../../../../../admin/helper/Lorder";
import CertificatFrom from "../CertificatFrom";
import { formatDate } from "../../../../../admin/helper/dateFormate";
import CertificatEditFrom from "../CertificatEditFrom";

const VerifiedCertificate = () => {
  // const [editCertificate, setEditCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState({ type: false });
  const { id } = useParams();
  const [certificatShow, setCertificatShow] = useState(false);

  const [editCertificate, setEditCertificate] = useState(false);
  const [certificateContent, setCertificateContent] = useState({
    dateCertificate: "7/17/2024",
    title: "Certificate of Completion",
    approval: "EMS BOARD APPROVAL #1194",
    university: "University Hospitals Bedford Medical Center",
    name: "Justin Magill",
    date: "July 2024",
    course: "Summer Emergencies",
    training: "TXA Training",
    position: "EMERGENCY MEDICAL TECHNICIAN",
    hours: "Total of 2.00 Hours Continued Education",
    additionalInfo:
      "General 0.00 hrs | Trauma hrs | Geriatric hrs | Pediatric hrs | Cardiac hrs",
    totalCE: "Total CE Hours Awarded 2.00",
    verifyDate: "Date 7/17/2024",
    directorSignature: "",
    director: "Dr. Donald Spaner",
    directorTitle: "Program Medical Director",
    coordinatorSignature: "",
    coordinator: "Daniel Ellenberger",
    coordinatorTitle: "Program Coordinator",
  });

  const handleContentChange = (key, value) => {
    setCertificateContent((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const [certificateLoader, setCertificateLoader] = useState(false);
  const [data, setData] = useState([]);
  const certificateDetailsGetListApi = async (id) => {
    setCertificateLoader(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${INSTRUCTOR_CERTIFICATE_DETAILS}${id}`
      );
      if (response.status) {
        setCertificateContent(response?.data?.data);
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setCertificateLoader(false);
  };
  const verifyHndleSubmit = async () => {
    const body = {
      verifyStatus: true,
    };
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}${CERTIFICATION_VERFIY_STATUS}${id}`,
        body
      );
      if (response.status) {
        ToastHandle(response?.data?.message, "success");
        certificateDetailsGetListApi(id);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    if (id) {
      certificateDetailsGetListApi(id);
    }
  }, [id]);
  return (
    <>
      <div className="text-end">
        <button
        className="bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0 mb-2"
          onClick={() =>
            setCertificateData(() => {
              setCertificateData({
                type: true,
                editData: certificateContent,
                certificateId: id,
              });
            })
          }
        >
          {!editCertificate ? "Edit" : "Close"}
        </button>
      </div>
      {!certificateLoader ? (
        <div className="main-certificate overflow-y-auto">
          <div className=" bg-white border border-[#F0F0F0] pt-4 pb-8 pl-4 pr-5 custom-shadow-cer  rounded-[10px]">
            <div className="flex justify-between items-center">
              <img src={logo} className="max-w-[200px]" />
              <p className="text-sm border-b border-[#DCDCDC] pb-[9px] px-[10px]">
                <strong>Date :</strong>
                {formatDate(certificateContent?.dateCertificate)}
              </p>
            </div>
            <div className="certificate-detail text-center mt-5">
              <h1
                className="lg:text-6xl text-[#CC4D1D] font-semibold mb-4 md:text-5xl text-3xl"
                contenteditable={editCertificate}
              >
                {certificateContent?.title}
                {/* Certificate of Completion */}
              </h1>
              <h2
                className="text-[#333333] italic lg:text-4xl font-medium md:text-3xl text-2xl"
                contenteditable={editCertificate}
              >
                {certificateContent?.approval}
              </h2>
              <h3
                className="text-[#333333] text-2xl md:mb-2 md:mt-5 my-3 font-medium"
                contenteditable={editCertificate}
              >
                {certificateContent?.university}
              </h3>
              <p
                className="text-[#000] italic text-xl max-w-[736px] mx-auto mb-9"
                contenteditable={editCertificate}
              >
                {certificateContent?.certifiesTitle}
              </p>
              <div className="middle-sec">
                <h2
                  className="italic font-semibold text-[#B70022] lg:text-[45px] md:text-[30px] text-[30px] mb-[18px]"
                  contenteditable={editCertificate}
                >
                  {certificateContent?.name}
                </h2>
                <div className="flex justify-center gap-4 max-w-[677.45px] mx-auto flex-wrap">
                  <p
                    className="md:text-[25px] text-[20px] text-[#000000]"
                    contenteditable={editCertificate}
                  >
                    {certificateContent?.date}
                  </p>
                  <p
                    className="md:text-[25px] text-[20px] text-[#000000]"
                    contenteditable={editCertificate}
                  >
                    {certificateContent?.course}
                    {/* Summer Emergencies */}
                  </p>
                  <p className="md:text-[25px] text-[20px] text-[#000000]">
                    {certificateContent?.training}
                  </p>
                </div>
                <h2 className="text-black font-semibold md:text-[35px] text-[25px] leading-[56px] my-[19px]">
                  {certificateContent?.position}
                </h2>
                <p className="md:text-[30px] md:leading-[48px]  text-[25px] font-medium text-black">
                  {certificateContent?.hours}
                </p>
                <p className="text-black md:text-[25px] text-[20px] font-mediums mt-[19px]">
                  {certificateContent?.additionalInfo}
                </p>
                <div className="flex md:justify-between justify-center items-center mx-auto max-w-[936px] md:mt-[141px] md:mb-[134px] my-[30px] flex-wrap">
                  <p className="md:text-[25px]  text-[20px] leading-[45px] text-black font-medium ">
                    {certificateContent?.totalCE}
                  </p>
                  <p className="md:text-[25px]  text-[20px] leading-[45px] text-black font-medium">
                    Date: {formatDate(certificateContent?.verifyDate)}
                    {/* Date 7/17/2024 */}
                  </p>
                </div>
                <div className="flex md:justify-between justify-center items-center flex-wrap gap-1">
                  <div>
                    <img
                      src={certificateContent?.directorSignature}
                      alt=""
                      style={{ width: "250px" }}
                    />
                    <p className="text-black text-xl font-medium leading-[32px]">
                      {certificateContent?.director}
                    </p>
                    <p className="text-black text-xl leading-[32px]">
                      {certificateContent?.directorTitle}
                    </p>
                  </div>
                  <div>
                    <img
                      src={certificateContent?.coordinatorSignature}
                      alt=""
                      style={{ width: "250px", height: "200px" }}
                    />
                    <p className="text-black text-xl font-medium leading-[32px]">
                      {certificateContent?.coordinator}
                    </p>
                    <p className="text-black text-xl leading-[32px]">
                      {certificateContent?.coordinatorTitle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            {!certificateContent?.verifyStatus && (
              <button
                onClick={verifyHndleSubmit}
                disabled={certificateContent?.verifyStatus ? true : false}
                className={`${
                  certificateContent?.verifyStatus
                    ? "bg-emerald-600"
                    : " bg-[#CC4D1D]"
                } text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
              >
                Verify
              </button>
            )}
          </div>
        </div>
      ) : (
        <TableLorder />
      )}
      {console.log(certificateData,'certificateData')}
      <CertificatEditFrom
        show={certificateData}
        hide={() => {
          setCertificateData({ type: false });
        }}
        afterApiRespApiCall={()=>certificateDetailsGetListApi(id)}
      />
    </>
  );
};

export default VerifiedCertificate;
