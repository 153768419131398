import React from "react";
import { useNavigate } from "react-router-dom";
import "./helper.css";
const Logout = ({ setProfileUpdateData }) => {
  const navigate = useNavigate();
  return (
    <div className="logout-main relative h-[100%]">
      <div className="bg-[#fff] py-12 px-5 rounded-xl xl:w-[800px] w-[600px] mx-auto login-pop">
        <h1 className="md:text-2xl  text-base font-semibold text-center mb-7">
          Are you sure you want to log out?
        </h1>
        <div className="flex justify-center gap-5">
          <button
            className=" bg-[#CC4D1D] text-white font-medium text-base rounded-[5px] py-2 md:px-7 px-4 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            onClick={() => {
              localStorage.clear();
              navigate("/login");
              setProfileUpdateData("");
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bg-[#fff] text-[#000]]font-medium text-base rounded-[5px] py-2 md:px-7 px-4  rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
