import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ProgressBar = ({ label, value, onChange }) => {
  return (
    <div style={{ margin: "31px 0" }}>
      <label style={{ display: "block", marginBottom: "10px" }}>{label}</label>
      <Slider
        onChange={(e) => onChange(e)}
        min={0}
        value={value||0}
        max={100}
        trackStyle={{ backgroundColor: "#1A5EC3", height: 5 }}
        handleStyle={{
          borderColor: "blue",
          height: 16,
          width: 16,
          marginLeft: -5,
          marginTop: -5,
          backgroundColor: "blue",
        }}
        railStyle={{ backgroundColor: "#E9ECEF", height: 5 }}
      />
    </div>
  );
};

export default ProgressBar;
