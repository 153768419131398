import axiosInstance, {
  BASE_URL,
} from "../../Components/apiHeader/axiosInstance";
import { COURSE_LIST, USERS_GET_PROFILE } from "./ApiEndPoint";
import ToastHandle from "./ToastMessage";

export const authKey = {
  admin: "admin",
  student: "student",
  instructor: "instructor",
};
export const limitData = 10;
export const createEditType = { add: "add", edit: "edit" };
export const localStorageAuthUserData = () => {
  const userData = localStorage.getItem("loginData");

  if (userData) {
    try {
      const parsedData = JSON.parse(userData);
      return parsedData;
    } catch (error) {
      return {};
    }
  }
  return {};
};

export const allUserDataApi = async (id) => {
  var userDataAll;
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}${USERS_GET_PROFILE}${id}`
    );
    if (response.status) {
      userDataAll = response?.data?.detail;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
  return userDataAll;
};

export const imageFirstUrl = `${process.env.REACT_APP_BASE_URL}/uploads/users/`;
