import React, { useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import {
  navLinks,
  instructorNavLinks,
  studentNavLinks,
} from "../../constants/navItems";
import { MdClose } from "react-icons/md";
import LOGO from "../../images/logo-university.png";
import Favicon from "../../images/favion.png";
import {
  authKey,
  localStorageAuthUserData,
} from "../../admin/helper/AuthUserData";

const Sidebar = ({ setOpen, open }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const authData = localStorageAuthUserData();
  const { detail } = authData ? authData : [];
  const { role } = detail ? detail : [];
  const activeDashboard = role;

  const isActive = (route) => {
    const currentPath = location.pathname;
    return currentPath === route ||
      (currentPath.startsWith(route) && route !== "/")
      ? "active_link"
      : "";
  };

  const handleMenuClick = (label) => {
    setActiveMenu(activeMenu === label ? null : label);
  };

  return (
    <div className={`sidebar`}>
      <div
        className={`sidebar-inner absolute lg:static ${
          open === "large"
            ? "w-[250px]"
            : open === "small"
            ? "small-sidebar w-[90px] bg-[#fff] text-black border-r border-gray-300"
            : "hidden"
        }`}
      >
        <div className="flex items-center justify-between sidebar-head">
          <img
            src={LOGO}
            className={`full-logo mx-auto w-[70%] ${
              open === "large" ? "bg-white p-3" : "bg-white p-2"
            }`}
          />
          <img
            src={Favicon}
            className={`small-logo mx-auto ${
              open === "large" ? "bg-white p-3" : "bg-white p-2 d-hidden"
            }`}
          />

          <MdClose
            onClick={() => setOpen(open === "large" ? "none" : "large")}
            className="text-2xl cursor-pointer block lg:hidden"
          />
        </div>

        <div className="list">
          {/* Administrator sidebar */}
          {activeDashboard === authKey?.admin && (
            <ul>
              {navLinks?.map((item) => {
                return (
                  <li key={item.label}>
                    {item.submenu ? (
                      <>
                        {open === "large" ? (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                            {item.label}
                          </div>
                        ) : (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                          </div>
                        )}
                        {activeMenu === item.label && (
                          <ul className="submenu-list">
                            {item.submenu.map((subItem, subIndex) => (
                              <li key={subItem.label}>
                                {open === "large" ? (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) && "active_link"
                                    } text-[#fff] text-sm   flex items-center gap-[5px] py-2 px-10  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.label}
                                  </Link>
                                ) : (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm   flex items-center gap-[5px] py-2 px-5  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.icon}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <>
                        {open === "large" ? (
                          <Link
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5`}
                            to={item?.link}
                          >
                            {item.icon}
                            {item.label}
                          </Link>
                        ) : (
                          <Link
                            className={`
                        ${isActive(item?.link) && "active_link-new"}
                        text-[#fff] text-2xl  flex items-center justify-center gap-[5px] py-4 px-[10px] hover:bg-[#2a79bd] hover:text-white hover-min-width`}
                            to={item?.link}
                          >
                            {item?.icon}
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {/* Instructor sidebar */}
          {activeDashboard === authKey?.instructor && (
            <ul>
              {instructorNavLinks?.map((item) => {
                return (
                  <li key={item.label}>
                    {item.submenu ? (
                      <>
                        {open === "large" ? (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                            {item.label}
                          </div>
                        ) : (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                          </div>
                        )}
                        {activeMenu === item.label && (
                          <ul className="submenu-list">
                            {item.submenu.map((subItem, subIndex) => (
                              <li key={subItem.label}>
                                {open === "large" ? (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) && "active_link"
                                    } text-[#fff] text-sm   flex items-center gap-[5px] py-2 px-10  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.label}
                                  </Link>
                                ) : (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm   flex items-center gap-[5px] py-2 px-5  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.icon}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                        {/* {activeMenu === item.label && (
                          <ul className="submenu-list">
                            {item.submenu.map((subItem) => (
                              <li key={subItem.label}>
                                {open === "large" ? (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm flex items-center gap-[5px] py-2 px-10  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.label}
                                  </Link>
                                ) : (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm flex items-center gap-[5px] py-2 px-5  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.icon}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )} */}
                      </>
                    ) : (
                      <>
                        {open === "large" ? (
                          <Link
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5`}
                            to={item?.link}
                          >
                            {item.icon}
                            {item.label}
                          </Link>
                        ) : (
                          <Link
                            className={`
                        ${isActive(item?.link) && "active_link-new"}
                        text-[#fff] text-2xl  flex items-center justify-center gap-[5px] py-4 px-[10px] hover:bg-[#2a79bd] hover:text-white hover-min-width`}
                            to={item?.link}
                          >
                            {item?.icon}
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {/* Student sidebar */}
          {activeDashboard === authKey?.student && (
            <ul>
              {studentNavLinks?.map((item) => {
                return (
                  <li key={item.label}>
                    {item.submenu ? (
                      <>
                        {open === "large" ? (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                            {item.label}
                          </div>
                        ) : (
                          <div
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5 cursor-pointer`}
                            onClick={() => handleMenuClick(item.label)}
                          >
                            {item.icon}
                          </div>
                        )}
                        {activeMenu === item.label && (
                          <ul className="submenu-list">
                            {item.submenu.map((subItem) => (
                              <li key={subItem.label}>
                                {open === "large" ? (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm flex items-center gap-[5px] py-2 px-10  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.label}
                                  </Link>
                                ) : (
                                  <Link
                                    className={`${
                                      isActive(subItem?.link) &&
                                      "active_link-new"
                                    } text-[#fff] text-sm flex items-center gap-[5px] py-2 px-5  hover:bg-[#2a79bd] hover:text-white`}
                                    to={subItem?.link}
                                  >
                                    {subItem.icon}
                                    {/* {subItem.label} */}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <>
                        {open === "large" ? (
                          <Link
                            className={`${
                              isActive(item?.link) && "active_link"
                            } text-[white] flex items-center gap-[10px] py-4 px-2.5`}
                            to={item?.link}
                          >
                            {item.icon}
                            {item.label}
                          </Link>
                        ) : (
                          <Link
                            className={`
                        ${isActive(item?.link) && "active_link-new"}
                        text-[#fff] text-2xl  flex items-center justify-center gap-[5px] py-4 px-[10px] hover:bg-[#2a79bd] hover:text-white hover-min-width`}
                            to={item?.link}
                          >
                            {item?.icon}
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
