import React, { useState } from 'react'
import Login from "../../../../src/images/login-img.png"
import { MdDomainVerification } from "react-icons/md";

const Index = () => {


    return (
        <>
            <div className="banner flex justify-center items-center flex-wrap py-10 lg:py-0">
                <div className="w-full md:w-1/2 px-[0px] 2xl:px-[150px] px-[150px] mx-auto mb-3 xl:mb-0">
                    <h1 className='md:px-10 px-5 font-semibold text-4xl mb-3'>Register</h1>
                    <p className='md:px-10 px-5 mb-8 text-[#333] text-base	leading-6'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                    <form className="space-y-6 md:px-10 px-5" action="#" method="POST">
                        <div className="mt-2">
                            <input id="name" name="name" type="text" autocomplete="off" placeholder='Enter first name' className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                        </div>
                        <div className="mt-2">
                            <input id="name" name="name" type="text" autocomplete="text" placeholder='Enter last name' className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                        </div>
                        <div className="mt-2 relative">
                            <input id="name" name="name" type="text" autocomplete="current-password" placeholder='Enter domain name' className="relative block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                            <button className="absolute right-[0px] top-[0px] bg-[#2a79bd] h-[44px] w-[44px] flex items-center justify-center">
                                <MdDomainVerification className="text-white text-2xl " />
                            </button>
                        </div>
                        <div className="mt-2">
                            <input id="email" name="email" type="email" autocomplete="email" placeholder='Enter your new email' className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                        </div>
                        <div className="mt-2">
                            <input id="password" name="password" type="password" autocomplete="current-password" placeholder='Enter your password' className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                        </div>
                        <div className="mt-2">
                            <input id="password" name="password" type="password" autocomplete="Confirm-password" placeholder='Confirm Password' className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3" />
                        </div>
                        <div>
                            <button type="submit" className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign up</button>
                        </div>
                    </form>

                </div>

                <div className='w-full md:w-1/2 flex items-center right-section'>
                    <img className='p-10' src={Login} />
                </div>

            </div>
        </>
    )
}

export default Index
