import React, { useEffect, useState } from "react";
import LOGO from "../../../images/logo-university.png";
import LoginImg from "../../../../src/images/login-img.png";
import "./Login.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import { useNavigate } from "react-router-dom";
import Lorder from "../../../admin/helper/Lorder";
import ToastHandle from "../../../admin/helper/ToastMessage";
import axiosInstance, { BASE_URL } from "../../apiHeader/axiosInstance";
import { authKey } from "../../../admin/helper/AuthUserData";
import { USERS_LOGIN } from "../../../admin/helper/ApiEndPoint";
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loadind, setLoading] = useState(false);
  const navigateHndle = (data, url) => {
    localStorage.setItem("loginData", JSON.stringify(data));
    navigate(url);
  };
  const loginHndle = async (data) => {
    setLoading(true);
    const { userEmail, password } = data;
    const body = { email: userEmail, password: password };
    try {
      const response = await axiosInstance.post(`${BASE_URL}${USERS_LOGIN}`, body);
      if (response.status) {
        const bcndRole = response?.data?.detail?.role;
        if (bcndRole === authKey?.admin) {
          navigateHndle(response?.data, "/");
        } else if (bcndRole === authKey?.student) {
          navigateHndle(response?.data, "/student/dashboard");
        } else if (bcndRole === authKey?.instructor) {
          navigateHndle(response?.data, "/instructor/dashboard");
        }
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  return (
    <div className="banner flex justify-center xl:items-center flex-wrap py-10 md:py-0">
      <div className="w-full md:w-1/2 px-[0]  xl:2xl:px-[220px]  mx-auto mb-4 lg:mb-0 ">
        <div className="2xl:absolute top-[40px] left-[40px] px-5">
          {" "}
          <img className="md:mb-20  mb-8" src={LOGO} />
        </div>
        <h1 className="md:px-10 xl:px-5 px-4 font-semibold md:text-4xl text-3xl mb-10 text-black">
          Login
        </h1>
        <form
          className=" md:px-10 xl:px-5 px-4"
          action="#"
          method="POST"
          onSubmit={handleSubmit(
            (data) => {
              loginHndle(data);
            },
            (err) => {
              console.log(err, "ee");
            }
          )}
        >
          <div className="mb-[25px]">
            <label
              for="email"
              className="block md:text-xl text-base font-medium leading-4 text-[#263A43] "
            >
              Email
            </label>
            <div className="mt-3">
              <input
                name="name"
                type="name"
                autocomplete="name"
                {...register("userEmail", { required: true })}
                placeholder="Enter user name"
                className="block w-full rounded-md border-0 h-[50px]  py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
              />
              {errors.userEmail?.type === "required" &&
                errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
            </div>
          </div>

          <div>
            <label
              for="password"
              className="block md:text-xl text-base font-medium leading-4 text-[#263A43]"
            >
              Enter your Password
            </label>
            <div className="mt-3">
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                placeholder="Type your password here"
                {...register("password", { required: true })}
                className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
              />
              {errors.password?.type === "required" &&
                errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
            </div>
          </div>
          <div className="flex items-center justify-between mt-5 mb-10 flex-wrap">
            <div class="flex items-center mb-5 md:mb-0">
              <input
                id="default-checkbox"
                type="checkbox"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-checkbox"
                class="ms-2 font-normal text-[#8E8E8E] text-sm"
              >
                Remember me
              </label>
            </div>
            <Link
              to="/forgetpassword"
              className="font-normal text-[#8E8E8E] text-sm  hover:opacity-75"
            >
              Forgot password?
            </Link>
          </div>
          <div className="">
            <button
              type="submit"
              className="login-btn flex  justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]"
            >
              {!loadind ? "Login" : <Lorder />}
            </button>
          </div>

          <p className="flex items-center justify-between mt-3 flex-wrap"> 
          Don't have an account?
            <select className="lg:w-[50%] p-3 rounded-md focus:outline-none md:w-[50%] w-[100%]" 
              name="cars"
              id="cars"
              onChange={(e) => {
                console.log(e.target.value,'e.target.valuee.target.value')
                navigate(e.target.value);
              }}
            >
              <option value="" hidden selected>
                Select
              </option>
              <option value="/register-student">Student</option>
              <option value="/register-instructor">Instructor</option>
            </select>
          </p>
        </form>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/2 xl:p-20 p-8 flex items-start lg:items-center right-section md:mt-0 mt-5">
        <img className="" src={LoginImg} />
      </div>
    </div>
  );
};

export default Login;
