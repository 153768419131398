import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import DashboardHeader from "./dashbordHeader/DashboardHeader";
import Studentprofile from "../../../images/stu-profile.png";
import { Link } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import { USERS_STUDENT_DASHBOARD_DATA } from "../../../admin/helper/ApiEndPoint";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import ToastHandle from "../../../admin/helper/ToastMessage";
const StudentDashboard = ({ profileUpdateData }) => {
  const studentProfileData = localStorageAuthUserData();
  const { detail } = studentProfileData;
  const {
    fullname,
    certification_number,
    expiry_date,
    phone,
    address,
    email,
    profilepic,
  } = profileUpdateData ? profileUpdateData : detail || {};
  const [data, setData] = useState([]);

  const studentGetListApi = async () => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_STUDENT_DASHBOARD_DATA}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };
  useEffect(() => {
    studentGetListApi();
  }, []);

  return (
    <div className="dashboard-section overflow-y-auto student-profile">
      <DashboardHeader data={data} />
      <div className="dashboard-items lg:gap-7 gap-3 grid md:grid-cols-3 grid-cols-1 xl:mb-8 mb-5">
        <div className="dashboard-topbar-item bg-[#B70022] rounded-[15px] px-5 py-5 flex items-center justify-between">
          <Link to="/student/courses">
            <p className="text-white font-medium xl:text-lg text-base">
              Course Browsing
            </p>
          </Link>
        </div>
        <Link to="/student/my-courses">
          <div className="dashboard-topbar-item bg-[#CC4D1D] rounded-[15px] px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              My Courses
            </p>
          </div>
        </Link>

        <Link to="/student/certificates">
          <div className="dashboard-topbar-item bg-[#B70022] rounded-[15px] px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Certificates
            </p>
          </div>
        </Link>
      </div>
      <div className="flex gap-3 md:gap-5 2xl:gap-8 flex-wrap">
        <div className="profile-img rounded-[10px] md:px-5 px-3s py-10 bg-section w-full md:w-[30%] lg:w-[30%] 2xl:w-[30%] ">
          <div className="mb-3">
            <img
              className="text-center mx-auto max-w-[150px] rounded-[50%] border-4 border-[#fff] h-[150px] object-cover"
              src={profilepic ? profilepic : Studentprofile}
              // src={ Studentprofile}
            />
          </div>
          <h3 className="text-center text-xl font-semibold text-black">
            {fullname}
          </h3>
          <p className="text-[#89868D] font-regular md:text-base text-sm text-center">
            Introduction of UI/UX
          </p>
          <div className="social-icon">
              <ul className="flex justify-center items-center gap-2 mt-3">
                <li>
                  <a href={`tel:${phone}`}>
                    <IoIosCall />
                  </a>
                </li>

                <li>
                  <a href={`mailto:${email}`}>
                    <HiMail />
                  </a>
                </li>
              </ul>
            </div>
        </div>
        <div className="w-full md:w-[67%] lg:w-[65%] 2xl:w-[66%] bg-white profile-info rounded-[10px] md:p-5 p-3">
          <div className="flex flex-wrap sm:gap-5 gap-3 md:mb-5 mb-2.5">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Name:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {fullname}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Certification Number:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {certification_number}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Expiration Date:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {expiry_date}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Phone number
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {phone}
            </p>
          </div>

          <div className="flex xl:flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm md:w-[40%] xl:w-[25%]">
              Address:
            </p>
            <p className="text-black font-medium md:text-base text-sm  text-wrap">
              {address}
            </p>
          </div>
          <div className="flex flex-wrap md:mb-5 mb-2.5 sm:gap-5 gap-3">
            <p className="text-[#89868D] font-medium md:text-base text-sm  md:w-[40%] xl:w-[25%]">
              E-mail:
            </p>
            <p className="text-black font-medium md:text-base text-sm">
              {email}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
