import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import DashboardHeader from "./dashbordHeader/DashboardHeader";
import StudentCoursesSection from "./studentCoursesSection/StudentCoursesSection";
import ToastHandle from "../../helper/ToastMessage";
import axiosInstance from "../../../Components/apiHeader/axiosInstance";
import { Link } from "react-router-dom";
const DashboardIndex = () => {
  const [filterData, setFilterData] = useState({});

  const courseGetListApi = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/dashboard/get-all-filters`
      );
      if (response.status) {
        setFilterData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    courseGetListApi();
  }, []);

  return (
    <div className="dashboard-section overflow-y-auto">
      <DashboardHeader filterData={filterData} />
      <div className="dashboard-items lg:gap-7 gap-3 grid md:grid-cols-3 grid-cols-1 xl:mb-8 mb-5">
        <Link to="/student-management">
          <div className="dashboard-topbar-item bg-[#B70022] rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Student Management
            </p>
          </div>
        </Link>

        <Link to="/course-management">
          <div className="dashboard-topbar-item bg-[#CC4D1D] rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Course Management
            </p>
          </div>
        </Link>

        <Link to="/evaluation-management">
          <div className="dashboard-topbar-item bg-[#B70022]   rounded-lg px-5 py-5 flex items-center justify-between">
            <p className="text-white font-medium xl:text-lg text-base">
              Evaluation Reports
            </p>
          </div>
        </Link>
      </div>
      <StudentCoursesSection filterData={filterData} />
    </div>
  );
};

export default DashboardIndex;
