import React from "react";
import Table from "../../../../../../Components/Table";
import Studentimage from "../../../../../../images/student.png";

const ViewStudentTable = () => {
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <img src={Studentimage} alt="Signatureimage" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification number", field: "certificationnumber" },
    { header: "Expiration date", field: "expirationdate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },
  ];

  const data = [
    {
      name: "Mitchal Jackson",
      certificationnumber: "#321",
      expirationdate: "27/05/2009",
      email: "abc@gmail.com",
      phoneNumber: "9876543210",
    },
  ];
  return <Table columns={columns} data={data} manageButton={true} />;
};

export default ViewStudentTable;
