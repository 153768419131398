import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./CourseManagement.css";
import CustomModal from "../../../Components/customModal";
import CourseManagementList from "./courseManagementList";

const CourseManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const [courseSearch, setCourSearch] = useState("");
  const fieldGroups = [
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "cname",
          name: "cname",
          label: "Course Name",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "sdate",
          name: "sdate",
          label: "Start Date",
          type: "date",
          placeholder: "",
        },
        {
          id: "edate",
          name: "edate",
          label: "End Date",
          type: "date",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "duration",
          name: "duration",
          label: "Duration",
          type: "text",
          placeholder: "",
        },
        {
          id: "cdays",
          name: "cdays",
          label: "Class Days",
          type: "select",
          options: [
            { value: "", label: "Select days" },
            { value: "usa", label: "Monday" },
            { value: "canada", label: "Tuesday" },
            { value: "canada", label: "Wednesday" },
            { value: "canada", label: "Friday" },
            { value: "canada", label: "Saturday" },
            { value: "canada", label: "Sunday" },
            // Add more options here
          ],
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "time",
          name: "time",
          label: "Class Time",
          type: "time",
          placeholder: "",
        },
        {
          id: "tzone",
          name: "tzone",
          label: "Time Zone",
          type: "time",
          placeholder: "",
        },
      ],
    },

    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "location",
          name: "location",
          label: "Location",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "price",
          name: "price",
          label: "Monthly Price $",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "description",
          name: "description",
          label: "Short Description",
          type: "textarea",
          placeholder: "",
        },
      ],
    },
  ];
  return (
    <div className="course-mangement overflow-y-auto">
      {/* Top filter */}
      <div className="filter mt-5 bg-white rounded-[10px] custom-shadow flex flex-wrap justify-between h-[75px]  px-5 items-center lg:mb-10 mb-5">
        <div className="relative lg:w-[50%] xl:w-[20%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            onChange={(e) => setCourSearch(e.target.value)}
            className=" ps-9 search-bar border border-[#F0F0F0] rounded-lg focus:outline-0 py-2 pr-3 text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
            placeholder="Search..."
          />
        </div>
        {/* <div className="flex flex-wrap gap-5 sm:mt-0 mt-3">
          <div className="dropdown inline-block relative">
            <button className=" text-[#CC4D1D] font-normal text-sm rounded-[5px]  py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0">
              All Courses
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
              </svg>
            </button>
            <ul className="shadow-xl dropdown-menu absolute top-[40px] left-[0px] hidden text-gray-700 pt-1 w-[150px]">
              <li className="">
                <Link
                  to="/"
                  className="rounded-t bg-white hover:bg-[#CC4D1D] hover:text-white text-[#CC4D1D] py-2 px-4 block whitespace-no-wrap"
                >
                  Lorem ipsum
                </Link>
              </li>
              <li className="">
                <Link
                  to="/"
                  className="bg-white hover:bg-[#CC4D1D] hover:text-white py-2 px-4  text-[#CC4D1D] block whitespace-no-wrap"
                >
                  Lorem ipsum
                </Link>
              </li>
            </ul>
          </div>
          <button
            onClick={() => setShowModal(true)}
            className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
            <FiPlus className="text-white text-sm me-1" />
            Add New Course
          </button>
        </div> */}
      </div>
      {/* Top filter */}
      {/* Course body */}
      <CourseManagementList courseSearch={courseSearch}/>
      {/* Course body */}
      <CustomModal
        heading="Add New Course"
        show={showModal}
        hide={() => setShowModal(false)}
        fieldGroups={fieldGroups}
      />
    </div>
  );
};

export default CourseManagement;
