import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import CustomModal from "../../../../Components/customModal";
import Studentimg from "../../../../images/student.png";
import { useNavigate } from "react-router-dom";
import {
  INSTRUCTOR_STUDNETS_LIST,
  USERS_DELETE_USER,
  USERS_STUDENT_LIST,
  USERS_UPDATE_USER,
  USERS_VERIFY_USER,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import {
  authKey,
  createEditType,
  limitData,
  localStorageAuthUserData,
} from "../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";

const StudentManagementTable = ({ searchTerm }) => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;
  const [studentModel, setStudentModel] = useState(false);
  const [EditGetData, setEditGetData] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  const navigate = useNavigate();
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Studentimg} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification Number", field: "certificationNumber" },
    { header: "Expiration Date", field: "expirationDate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },
    {
      header: "Status",
      field: "status",
      render: (status) => (
        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
          {status}
        </button>
      ),
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  let filterData = SearchFilter(data, searchTerm);

  const studentGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        mainRole === authKey?.instructor
          ? `${BASE_URL}${INSTRUCTOR_STUDNETS_LIST}${mainId}?page=${currentPage}&limit=${limitData}`
          : `${BASE_URL}${USERS_STUDENT_LIST}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const studentGetData = response?.data?.detail;
        const convertData = studentGetData?.map((instruct) => {
          const {
            _id,
            role,
            phone,
            fullname,
            expiry_date,
            email,
            certification_number,
            agency,
            address,
            is_verify,
            profilepic,
          } = instruct;
          return {
            _id,
            role,
            agency,
            address,
            name: fullname,
            certificationNumber: certification_number,
            expirationDate: expiry_date,
            email: email,
            phoneNumber: phone,
            status: is_verify ? "verify" : "Not verify",
            profilepic: profilepic,
          };
        });

        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  //

  // input field
  const [fieldGroups, setFieldGroups] = useState("");
  const {
    _id,
    name,
    email,
    expirationDate,
    phoneNumber,
    address,
    certificationNumber,
    lname,
    description,
  } = EditGetData;

  useEffect(() => {
    if (EditGetData !== "") {
      setStudentModel(true);
      setFieldGroups([
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "fname",
              name: "fname",
              label: "First name",
              type: "text",
              value: name || "",
              placeholder: "",
            },
            {
              id: "lname",
              name: "lname",
              label: "Last name",
              type: "text",
              value: lname || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "cername",
              name: "cername",
              label: "Certification Number",
              type: "text",
              value: certificationNumber || "",
              placeholder: "",
            },
            {
              id: "exdate",
              name: "exdate",
              label: "Expiration Date",
              type: "date",
              value: expirationDate || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "email",
              name: "email",
              label: "E-Mail",
              type: "email",
              value: email || "",
              placeholder: "",
            },
            {
              id: "phone",
              name: "phone",
              label: "Telephone Number",
              type: "tel",
              value: phoneNumber || "",
              placeholder: "",
              pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "address",
              name: "address",
              label: "Address",
              type: "text",
              value: address || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "description",
              name: "description",
              label: "Short Description",
              type: "textarea",
              value: description || "",
              placeholder: "",
            },
          ],
        },
      ]);
    }
  }, [EditGetData]);

  // update student api integration
  const [loading, setLoading] = useState(false);
  const studentEditApiHndle = async (data, type) => {
    if (type === createEditType?.edit) {
      setLoading(true);
      const {
        fname,
        lname,
        phone,
        exdate,
        email,
        description,
        cername,
        address,
      } = data;
      const body = {
        userId: _id,
        fullname: fname,
        email: email,
        certification_number: cername,
        expiry_date: exdate,
        join_date: "2024-01-01",
        phone: phone,
        address: address,
        agency: "Agency Name",
        description: description,
      };
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_UPDATE_USER}`,
          body
        );
        if (response.status) {
          studentGetListApi();
          setStudentModel(false);
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };
  // update student api integration
  // delete student api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const studentDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_USER}${id}`
      );
      if (response.status) {
        studentGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete student api integration

  // verify student api integration
  const studentVerifyApiHndle = async (id) => {
    const body = {
      userId: id,
      status: true,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_VERIFY_USER}`,
        body
      );
      if (response.status) {
        studentGetListApi();
        ToastHandle(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  useEffect(() => {
    studentGetListApi();
  }, [currentPage]);

  return (
    <>
      <Table
        columns={columns}
        data={filterData}
        modelModel={(data) => setEditGetData(data)}
        navigateHndle={(id) => navigate(`/Student-profile/${id}`)}
        verifyHndle={(id) => studentVerifyApiHndle(id)}
        deleteHndle={(id) => studentDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <CustomModal
        heading="Edit Student"
        show={studentModel}
        hide={() => {
          setStudentModel(false);
          setEditGetData("");
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={(data, type) => studentEditApiHndle(data, type)}
        loading={loading}
      />
    </>
  );
};

export default StudentManagementTable;
