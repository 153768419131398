import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import CustomModal from "../../../../Components/customModal";
import axios from "axios";
import {
  USERS_ADD_HOSPITAL,
  USERS_DELETE_HOSPITAL,
  USERS_GET_HOSPITAL,
  USERS_UPDATE_HOSPITAL,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import { createEditType, imageFirstUrl, limitData } from "../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
  config,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  imageFrame,
  profileInterFaceType,
} from "../../../../Components/commonFunction/CommonFunction";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";


const HospitalManagementTable = ({
  hospitalManagementOpen,
  hospitalManagementCloseOpen,
  searchTerm,
}) => {
  const navigate = useNavigate();

  // table
  const columns = [
    {
      header: "Sr. No.",
      field: "Sr",
      render: (data) => (
        <div className="flex items-center py-3  gap-3">
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Hospital Name", field: "hospitalname" },
    { header: "Location:", field: "location" },
    { header: "EMS Coordinator", field: "ems" },
    {
      header: "Medical Control",
      field: "medicalcontrol",
      render: (status) => (
        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
          {status}
        </button>
      ),
    },
    {
      header: "Hospital Image",
      field: "hospitalimage",
      render: (data) => {
        return imageFrame(data);
      },
    },
  ];
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const filterData = SearchFilter(data, searchTerm);
  const hospitalManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_HOSPITAL}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const hospitalData = response?.data?.detail;
        const convertData = hospitalData?.map((hopital, hopitalIndex) => {
          const {
            _id,
            name,
            location,
            ems_coordinator,
            medical_control,
            image,
          } = hopital;
          return {
            Sr: hopitalIndex + 1,
            hospitalname: name,
            location: location,
            ems: ems_coordinator,
            medicalcontrol: medical_control,
            hospitalimage: image[0],
            _id,
          };
        });
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  // table

  // input fields
  const [fieldGroups, setFieldGroups] = useState();
  const [editGetData, setEditGetData] = useState("");

  const { _id, ems, hospitalimage, hospitalname, location, medicalcontrol } =
    editGetData;

  useEffect(() => {
    if (editGetData !== "" || hospitalManagementOpen) {
      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "hname",
              name: "hname",
              label: "Hospital Name",
              type: "text",
              value: hospitalname || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "location",
              name: "location",
              label: "Location",
              type: "textarea",
              value: location || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "medicalcontrol",
              name: "medicalcontrol",
              label: "Medical Control",
              type: "selectNormal",
              selectedValues: medicalcontrol || [],
              options: [
                { value: "Medical", name: "Medical" },
                { value: "Medical", name: "Medical" },
              ],
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "ems",
              name: "ems",
              label: "EMS Coordinator",
              type: "textarea",
              value: ems || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "hospitalImage",
              name: "hospitalImage",
              label: "Hospital Image",
              type: "file",
              imageShow: hospitalimage || "",
              placeholder: "",
            },
          ],
        },
      ]);
    }
  }, [editGetData, hospitalManagementOpen]);
  //input fields

  const [loading, setLoading] = useState(false);
  const hospitalManagementAddApi = async (data, type, otherData) => {
    if (type === createEditType?.add) {
      setLoading(true);
      const { hname, location, ems, hospitalImage, medicalcontrol } = data;
      let formData = new FormData();
      formData.append("name", hname);
      formData.append("location", location);
      formData.append("medical_control", medicalcontrol);
      formData.append("ems_coordinator", ems);
      formData.append("image", hospitalImage[0]);

      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_ADD_HOSPITAL}`,
          formData,
          config
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          hospitalManagementCloseOpen(false);
          hospitalManagementGetListApi();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    } else if (type === createEditType?.edit) {
      setLoading(true);
      const { selectedValues } = otherData;
      const { hname, location, ems, hospitalImage } = data;
      let formData = new FormData();
      formData.append("id", _id);
      formData.append("name", hname);
      formData.append("location", location);
      formData.append("medical_control", medicalcontrol);
      formData.append("ems_coordinator", ems);
      formData.append(
        "image",
        hospitalImage[0] !== undefined ? hospitalImage[0] : hospitalimage
      );

      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_UPDATE_HOSPITAL}`,
          formData,
          config
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          hospitalManagementCloseOpen(false);
          hospitalManagementGetListApi();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };
  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const hospitalDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_HOSPITAL}${id}`
      );
      if (response.status) {
        hospitalManagementGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete course api integration

  useEffect(() => {
    hospitalManagementGetListApi();
  }, [currentPage]);

  return (
    <>
      <Table
        columns={columns}
        data={filterData}
        modelModel={(data) => {
          setEditGetData(data);
          hospitalManagementCloseOpen(true);
        }}
        navigateHndle={(id) => {
          let object = { id, type: profileInterFaceType?.hospitalM };
          navigate(`/profile/${JSON.stringify(object)}`);
        }}
        deleteHndle={(id) => hospitalDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <CustomModal
        heading={`Hospital ${editGetData !== "" ? "Edit" : "Management"} `}
        show={hospitalManagementOpen}
        hide={() => {
          setEditGetData("");
          hospitalManagementCloseOpen(false);
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={hospitalManagementAddApi}
        loading={loading}
      />
    </>
  );
};

export default HospitalManagementTable;
