import React, { useEffect, useState } from "react";
import FilterBar from "../../../Components/FilterBar";
import StudentListTable from "./studentList/StudentListTable";
import CertificatFrom from "./studentList/CertificatFrom";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import { INSTRUCTOR_CERTIFICATION_STUDENT_LIST } from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";
import {
  limitData,
  localStorageAuthUserData,
} from "../../../admin/helper/AuthUserData";
import Pagination from "../../../Components/commonFunction/Pagination";

const AddCertificate = () => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainRole = detail?.role;
  const mainId = detail?._id;

  const [certificatShow, setCertificatShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "Evaluation Report";

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableLorder, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  let filterData = SearchFilter(data, searchTerm);

  const studentGetListApi = async () => {
    try {
      setTableLoading(true);
      const response = await axiosInstance.get(
        `${BASE_URL}${INSTRUCTOR_CERTIFICATION_STUDENT_LIST}${mainId}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        setData(response?.data?.data);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  useEffect(() => {
    studentGetListApi();
    console.log(currentPage, "currentPagecurrentPage");
  }, [currentPage]);

  return (
    <div className="student-managet overflow-y-auto">
      <FilterBar
        onSearch={handleSearch}
        dropdownOptions={dropdownOptions}
        onDropdownSelect={handleDropdownSelect}
        DropdownName={DropdownName}
      />
      <div className="d-flex justify-between">
        <div>
          <h2 className="text-lg font-medium text-black mb-[15px]">
            Student List
          </h2>
        </div>
        <div>
          <button
            onClick={() => setCertificatShow(true)}
            className="bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0 mb-2"
          >
            Certificat Add
          </button>
        </div>
      </div>
      <StudentListTable data={filterData} tableLorder={tableLorder} />
      <Pagination
        currentPage={Number(currentPage)}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <CertificatFrom
        show={certificatShow}
        hide={(type) => setCertificatShow(type)}
        afterApiRespApiCall={() => studentGetListApi()}
      />
    </div>
  );
};

export default AddCertificate;
