import React, { useEffect, useState } from "react";
import CourseImg from "../../../../images/course.png";
import { FaRegUserCircle } from "react-icons/fa";
import { TbClock24 } from "react-icons/tb";
import { CiClock2 } from "react-icons/ci";
import { Link } from "react-router-dom";
import { localStorageAuthUserData } from "../../../../admin/helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import { USERS_COURSE_LIST } from "../../../../admin/helper/ApiEndPoint";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import ToastHandle from "../../../../admin/helper/ToastMessage";
import { TableLorder } from "../../../../admin/helper/Lorder";
import { DataEmpty } from "../../../../Components/commonFunction/CommonFunction";

const CourseManagementList = ({ courseSearch }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const loginData = localStorageAuthUserData();
  const { detail } = loginData;
  const userIdMain = detail?._id;
  const filterData = SearchFilter(data, courseSearch);
  const courseGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_COURSE_LIST}${userIdMain}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (userIdMain) {
      courseGetListApi();
    }
  }, [userIdMain]);

  return (
    <div className="course-mangement-inner ">
      <h2 className="mb-[11px] text-[22px] font-medium">Ongoing Courses</h2>
      {!tableLoading ? (
        <>
          {filterData?.length > 0 ? (
            <div className="grid md:grid-cols-3 grid-cols-1 xl:gap-8 gap-3">
              {filterData?.map((courseItem) => {
                const { _id, name, description, start_date, monthly_price } =
                  courseItem;
                return (
                  <div className="bg-white  rounded-[10px]">
                    <img className="w-full" src={CourseImg} />
                    <div className="p-3 2xl:px-5 xl:px-3">
                      <h3 className="text-lg font-medium text-black mb-2">
                        Hospital : {name} (ICU)
                      </h3>
                      <p className="text-[#626263] font-normal text-sm xl:mb-3">
                        {description}
                      </p>
                      <div className="flex flex-wrap items-center 2xl:gap-5 xl:gap-3 gap-4 xl:py-5 py-2">
                        <div className="flex items-center 2xl:gap-2 xl:gap-1 gap-2">
                          <FaRegUserCircle className="text-[#89868D]" />
                          <p className="text-[#89868D] font-normal text-sm">
                            Enroll 26
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <CiClock2 className="text-[#89868D]" />
                          <p className="text-[#89868D] font-normal text-sm">
                            Start {start_date}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <TbClock24 className="text-[#89868D]" />
                          <p className="text-[#89868D] font-normal text-sm">
                            CE hours:20
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-[#B1B1B1] 2xl:px-5 px-3  pt-4 pb-4 flex items-center justify-between">
                      <div className="flex items-center justify-between gap-[10px]">
                        <Link
                          to={`/course-mangement/${_id}`}
                          className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                        >
                          View
                        </Link>
                        <button className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0">
                          Ongoing
                        </button>
                      </div>

                      <p className="text-[#CC4D1D] text-xs font-medium">
                        Monthly Price $:{monthly_price}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
    </div>
  );
};

export default CourseManagementList;
