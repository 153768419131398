import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../helper/ErrorMessage";
import { useForm } from "react-hook-form";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import { COURSE_ADD, COURSE_UPDATE } from "../../../../helper/ApiEndPoint";
import ToastHandle from "../../../../helper/ToastMessage";

const CourseEditModel = ({ show, hide, updateAfterRes }) => {
  const { editData } = show;
  console.log(editData, "showshow");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  //  mutileSelected
  const [selectedValues, setSelectedValues] = useState([]);
  const multiselectData = [
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Friday", name: "Friday" },
    { value: "Saturday", name: "Saturday" },
    { value: "Sunday", name: "Sunday" },
  ];

  const [selectedValueEmpty, setSelectedValueEmpty] = useState({
    errorType: false,
  });
  const handleSelect = (selectedList, selectedItem) => {
    setSelectedValues([...selectedValues, selectedItem.value]);
    setSelectedValueEmpty({
      errorType: false,
    });
  };

  const handleRemove = (selectedList, removedItem) => {
    setSelectedValues(
      selectedValues.filter((value) => value !== removedItem.value)
    );
  };
  //   const [selectedValueEmpty, setSelectedValueEmpty] = useState({
  //     errorType: false,
  //   });
  //   const [selectedValues, setSelectedValues] = useState([]);

  //   const handleSelect = (selectedList) => {
  //     setSelectedValues([...selectedList.map((item) => item)]);
  //     setSelectedValueEmpty({
  //       errorType: false,
  //     });
  //   };

  //   const handleRemove = (removedItem) => {
  //     setSelectedValues(removedItem);
  //   };
  //   const multiselectData = [
  //     { value: "Monday", name: "Monday" },
  //     { value: "Tuesday", name: "Tuesday" },
  //     { value: "Wednesday", name: "Wednesday" },
  //     { value: "Tuesday", name: "Tuesday" },
  //     { value: "Friday", name: "Friday" },
  //     { value: "Saturday", name: "Saturday" },
  //     { value: "Sunday", name: "Sunday" },
  //     // Add more options here
  //   ];
  const [formLoading, setFormLoading] = useState(false);
  const onSubmit = async (data) => {
    const {
      courseName,
      startDate,
      endDate,
      duration,
      startTime,
      endTime,
      location,
      monthlyPrice,
      description,
    } = data;
    if (startTime > endTime) {
      const body = {
        id: editData?._id,
        name: courseName,
        start_date: startDate,
        end_date: endDate,
        duration: duration,
        class_days: selectedValues?.map((days) => {
          return days?.value ? days?.value : days;
        }),
        // join_date: "2024-09-09",
        classtimefrom: startTime,
        classtimeto: endTime,
        // timezone: "America/New_York",
        location: location,
        monthly_price: monthlyPrice,
        description: description,
      };
      setFormLoading(true);
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${COURSE_UPDATE}`,
          body
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          updateAfterRes();
          hide();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setFormLoading(false);
    } else {
      ToastHandle("Please check time range.", "danger");
    }
  };

  useEffect(() => {
    if (editData) {
      const {
        name,
        start_date,
        end_date,
        duration,
        class_days,
        classtimefrom,
        classtimeto,
        location,
        monthly_price,
        description,
      } = editData;
      reset({
        courseName: name,
        startDate: start_date,
        endDate: end_date,
        duration: duration,
        startTime: classtimefrom,
        endTime: classtimeto,
        location: location,
        monthlyPrice: monthly_price,
        description: description,
      });
      setSelectedValues(class_days);
    }
  }, [editData]);
  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Edit Course
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Course Name{" "}
                    </label>
                    <input
                      type="text"
                      {...register("courseName", {
                        required: true,
                      })}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.courseName && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5 my-4">
                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        Start Date
                      </label>
                      <input
                        type="date"
                        {...register("startDate", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.startDate && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        End Date
                      </label>
                      <input
                        {...register("endDate", {
                          required: true,
                        })}
                        type="date"
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.endDate && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        Duration
                      </label>
                      <input
                        type="number"
                        {...register("duration", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      <div>Week</div>
                      {errors.duration && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>

                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        Class Days
                      </label>
                      {/* <Multiselect
                        onKeyPressFn={function noRefCheck() {}}
                        onSearch={function noRefCheck() {}}
                        onRemove={handleRemove}
                        onSelect={handleSelect}
                        displayValue="name"
                        selectedValues={selectedValues?.map((seletedItem) => {
                          return {
                            value:
                              seletedItem?._id !== undefined
                                ? seletedItem?._id
                                : seletedItem,
                            name: seletedItem,
                          };
                        })}
                        options={multiselectData?.map((fieldLable) => {
                          return {
                            value:
                              fieldLable?._id !== undefined
                                ? fieldLable?._id
                                : fieldLable?.value,
                            name: fieldLable?.name,
                          };
                        })}
                      /> */}
                      <Multiselect
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                        displayValue="name"
                        selectedValues={multiselectData.filter((item) =>
                          selectedValues.includes(item.value)
                        )}
                        options={multiselectData}
                      />
                      {selectedValueEmpty?.errorType &&
                        errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-5 my-4">
                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        Start Time
                      </label>
                      <input
                        type="time"
                        {...register("startTime", {
                          required: true,
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.startTime && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor=""
                        className="block mb-2 text-sm font-normal text-[#727272]"
                      >
                        End Time
                      </label>
                      <input
                        {...register("endTime", {
                          required: true,
                        })}
                        type="time"
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {errors.endTime && (
                        <>
                          {errorMessageShow(
                            errorEndPoint?.THIS_FIELD_IS_REQUIRED
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="my-4">
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Location{" "}
                    </label>
                    <input
                      {...register("location", {
                        required: true,
                      })}
                      type="text"
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.location && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Monthly Price ${" "}
                    </label>
                    <input
                      {...register("monthlyPrice", {
                        required: true,
                      })}
                      type="number"
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.monthlyPrice && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <label
                      for="description"
                      class="block mb-2 text-sm font-normal text-[#727272]"
                    >
                      Short Description
                    </label>
                    <textarea
                      {...register("description")}
                      id="description"
                      name="description"
                      placeholder=""
                      class="border border-[#D9D9D9] p-3 h-[100px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    ></textarea>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-5 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                        if (selectedValues?.length === 0) {
                          setSelectedValueEmpty({ errorType: true });
                          return null;
                        }
                      }
                    )}
                  >
                    {!formLoading ? "Update" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CourseEditModel;
