import React from "react";
import Student from "../../../../images/student-icon.png";
import Instructor from "../../../../images/Instructor.png";
import Course from "../../../../images/Courses.png";
const DashboardHeader = ({ instructorDashboard }) => {
  const { totalStudents, totalCourses, evaluations } = instructorDashboard;
  console.log(
    instructorDashboard,
    "instructorDashboardinstructorDashboard",
    instructorDashboard
  );
  return (
    <div className="dashboard-topbar lg:gap-7 gap-3 grid md:grid-cols-3 grid-cols-1 xl:mb-8 mb-5">
      <div className="dashboard-topbar-grid bg-white rounded-lg px-5 xl:py-7 py-4 flex items-center justify-between">
        <img src={Student} />
        <div className="">
          <h3 className="text-[#B70022] font-bold text-xl">{totalStudents}</h3>
          <p className="text-[#89868D] font-normal text-lg">Students</p>
        </div>
      </div>
      <div className="dashboard-topbar-grid bg-white rounded-lg px-5 xl:py-7 py-4 flex items-center justify-between">
        <img src={Course} />
        <div className="">
          <h3 className="text-[#B70022] font-bold text-xl">{totalCourses}</h3>
          <p className="text-[#89868D] font-normal text-lg">Courses</p>
        </div>
      </div>{" "}
      <div className="dashboard-topbar-grid bg-white rounded-lg px-5 xl:py-7 py-4 flex items-center justify-between">
        <img src={Instructor} />
        <div className="">
          <h3 className="text-[#B70022] font-bold text-xl">+{evaluations?.total_evaluations}</h3>
          <p className="text-[#89868D] font-normal text-lg">Evaluations</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
