import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import CustomModal from "../../../../Components/customModal";
import { Link } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import {
  USERS_ADD_AGENCY,
  USERS_DELETE_AGENCY,
  USERS_GET_AGENCY,
  USERS_UPDATE_AGENCY,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import { createEditType, limitData } from "../../../helper/AuthUserData";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";

const AgencyManagementTable = ({
  agencyManagementOpen,
  agencyManagementClose,
  searchTerm,
}) => {
  // table
  const columns = [
    {
      header: "Sr. No.",
      field: "Sr",
      render: (data) => (
        <div className="flex items-center py-3 gap-3">
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Name of Agency", field: "agencyname" },
    { header: "Address", field: "address" },
    { header: "Phone", field: "phone" },
    { header: "Fire Chief Name", field: "chiefname" },
    { header: "Fire Chief Phone", field: "chiefphone" },
    { header: "Fire Chief Email", field: "chiefemail" },
    {
      header: "Student",
      field: "student",
      render: () => (
        <Link to="/view-student">
          <button className="btn-view text-white font-normal text-sm rounded-[5px] p-2 inline-flex justify-between items-center bg-[#CC4D1D] focus:outline-0">
            View
          </button>
        </Link>
      ),
    },
  ];

  // const data = [
  //   {
  //     Sr: "#1",
  //     agencyname: "Creative Edge. Digital Spark.",
  //     address: "Casa n. 10, strada n. 6, Australia",
  //     phone: "9876541230",
  //     chiefname: "John Jassica",
  //     chiefphone: "0123456987",
  //     chiefemail: "abc@gmail.com",
  //     student: null,
  //   },
  // ];
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const filterData = SearchFilter(data, searchTerm);
  const agencyManagementGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${USERS_GET_AGENCY}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const agencyManagementGetData = response?.data?.detail;
        const convertData = agencyManagementGetData?.map(
          (agencyItem, agencyIndex) => {
            const {
              _id,
              name,
              address,
              phone,
              fire_chief_name,
              fire_chief_phone,
              fire_chief_email,
            } = agencyItem;
            return {
              _id,
              Sr: agencyIndex + 1,
              agencyname: name,
              address: address,
              phone: phone,
              chiefname: fire_chief_name,
              chiefphone: fire_chief_phone,
              chiefemail: fire_chief_email,
              student: null,
            };
          }
        );
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  //table

  // input fields
  // const fieldGroups = [
  //   {
  //     layout: "md:grid-cols-1 grid-cols-1",
  //     fields: [
  //       {
  //         id: "agencyname",
  //         name: "agencyname",
  //         label: "Name of Agency",
  //         type: "text",
  //         placeholder: "",
  //       },
  //     ],
  //   },
  //   {
  //     layout: "md:grid-cols-1 grid-cols-1",
  //     fields: [
  //       {
  //         id: "address",
  //         name: "address",
  //         label: "Address",
  //         type: "text",
  //         placeholder: "",
  //       },
  //     ],
  //   },
  //   {
  //     layout: "md:grid-cols-1 grid-cols-1",
  //     fields: [
  //       {
  //         id: "phone",
  //         name: "phone",
  //         label: "Phone",
  //         type: "tel",
  //         placeholder: "",
  //         pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
  //       },
  //     ],
  //   },
  //   {
  //     layout: "md:grid-cols-2 grid-cols-1",
  //     fields: [
  //       {
  //         id: "chiefname",
  //         name: "chiefname",
  //         label: "Fire Chief Name",
  //         type: "text",
  //         placeholder: "",
  //       },
  //       {
  //         id: "chiefphone",
  //         name: "chiefphone",
  //         label: "Fire Chief Phone",
  //         type: "tel",
  //         placeholder: "",
  //         pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
  //       },
  //     ],
  //   },
  //   {
  //     layout: "md:grid-cols-1 grid-cols-1",
  //     fields: [
  //       {
  //         id: "email",
  //         name: "email",
  //         label: "Fire Chief Email",
  //         type: "email",
  //         placeholder: "",
  //       },
  //     ],
  //   },
  // ];

  const [fieldGroups, setFieldGroups] = useState("");
  const [editGetData, setEditGetData] = useState("");

  const { _id, address, agencyname, chiefname, chiefphone, phone, chiefemail } =
    editGetData;

  useEffect(() => {
    if (editGetData !== "" || agencyManagementOpen) {
      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "agencyname",
              name: "agencyname",
              label: "Name of Agency",
              type: "text",
              placeholder: "",
              value: agencyname,
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "address",
              name: "address",
              label: "Address",
              type: "text",
              placeholder: "",
              value: address,
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "phone",
              name: "phone",
              label: "Phone",
              type: "tel",
              placeholder: "",
              pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
              value: phone,
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "chiefname",
              name: "chiefname",
              label: "Fire Chief Name",
              type: "text",
              placeholder: "",
              value: chiefname,
            },
            {
              id: "chiefphone",
              name: "chiefphone",
              label: "Fire Chief Phone",
              type: "tel",
              placeholder: "",
              pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
              value: chiefphone,
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "email",
              name: "email",
              label: "Fire Chief Email",
              type: "email",
              placeholder: "",
              value: chiefemail,
            },
          ],
        },
      ]);
    }
  }, [editGetData, agencyManagementOpen]);
  // input fields
  const [loading, setLoading] = useState(false);
  const agencyManagementAddApi = async (data, type, otherData) => {
    if (type === createEditType?.add) {
      setLoading(true);
      const { agencyname, address, phone, chiefname, email, chiefphone } = data;
      let body = {
        name: agencyname,
        address: address,
        phone: phone,
        fire_chief_name: chiefname,
        fire_chief_email: email,
        fire_chief_phone: chiefphone,
      };
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_ADD_AGENCY}`,
          body
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          agencyManagementClose(false);
          agencyManagementGetListApi();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    } else if (type === createEditType?.edit) {
      setLoading(true);
      const { agencyname, address, phone, chiefname, email, chiefphone } = data;
      let body = {
        id: _id,
        name: agencyname,
        address: address,
        phone: phone,
        fire_chief_name: chiefname,
        fire_chief_email: email,
        fire_chief_phone: chiefphone,
      };
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${USERS_UPDATE_AGENCY}`,
          body
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          agencyManagementClose(false);
          agencyManagementGetListApi();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const agencyDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_AGENCY}${id}`
      );
      if (response.status) {
        agencyManagementGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete course api integration

  useEffect(() => {
    agencyManagementGetListApi();
  }, [currentPage]);

  return (
    <div>
      <Table
        columns={columns}
        data={filterData}
        manageButton={false}
        modelModel={(data) => {
          setEditGetData(data);
          agencyManagementClose(true);
        }}
        deleteHndle={(id) => agencyDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <CustomModal
        heading="Agency Management"
        show={agencyManagementOpen}
        hide={() => {
          agencyManagementClose(false);
          setEditGetData({});
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={agencyManagementAddApi}
        loading={loading}
      />
    </div>
  );
};

export default AgencyManagementTable;
