import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avtar from "../../images/Avatar.png";
import { CiSearch } from "react-icons/ci";
import Notification from "../../images/notufication-icon.png";
import "./style.css";
import { FaBarsStaggered } from "react-icons/fa6";
import Img from "../../images/user.png";
import Img1 from "../../images/imag3.png";
import Img2 from "../../images/img1.png";
import {
  allUserDataApi,
  localStorageAuthUserData,
} from "../../admin/helper/AuthUserData";

const Header = ({ setOpen, open, profileUpdateData }) => {
  const loginUserData = localStorageAuthUserData();
  const { detail } = loginUserData || {};
  const { role, fullname, profilepic } = profileUpdateData
    ? profileUpdateData
    : detail || {};

  return (
    <>
      <div
        className={`justify-between  items-center px-3 lg:px-10 py-3 bg-[#FBFBFB] hidden lg:flex header-inner w-full
        `}
      >
        <div className="flex items-center gap-5">
          <FaBarsStaggered
            onClick={() => setOpen(open === "small" ? "large" : "small")}
            className="text-2xl cursor-pointer"
          />
          <h1 className="font-medium 2xl:text-lg text-black lg:text-base">
            Welcome {fullname}
          </h1>
        </div>

        <div className="flex items-center gap-8 w-1/2 md:w-3/4  justify-end">
          <div className="relative lg:w-[50%] xl:w-[25%] relative">
            {/* <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
            <input
            type="search"
           
            className="search-bar rounded-lg focus:outline-0 py-2  0px 1px 4px] text-base relative w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E] px-9"
            placeholder="Search..."
          /> */}
          </div>
          <div className="dropdown inline-block relative">
            {/* <button className=" text-[#CC4D1D] font-normal text-sm rounded-[5px] rounded inline-flex items-center  focus:outline-0">
              <img src={Notification} />
            </button> */}
            <div className="bg-white rounded-[10px] dropdown-menu absolute top-[45px] right-[-140px] hidden text-gray-700  w-[300px]  notification-sec">
              <div className="border-b border-[#EEEEEE] w-full">
                <p className="text-[#CC4D1D] font-semibold text-base px-3 py-4 text-center">
                  Notification
                </p>
              </div>
              <div className="notification-body px-5">
                <Link to="">
                  <div className="flex items-center gap-4 py-3 border-b border-[#EEEEEE]">
                    <img src={Img} />
                    <div className="">
                      <p className="text-[#89868D] font-medium text-xs mb-1">
                        Report created successfully
                      </p>
                      <p className="text-[#B3B3B3] font-normal text-[10px]">
                        29 July 2020 - 02:26 PM
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="">
                  <div className="flex items-center gap-4 py-3 border-b border-[#EEEEEE]">
                    <img src={Img2} />
                    <div className="">
                      <p className="text-[#89868D] font-medium text-xs mb-1">
                        Report created successfully
                      </p>
                      <p className="text-[#B3B3B3] font-normal text-[10px]">
                        29 July 2020 - 02:26 PM
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="">
                  <div className="flex items-center gap-4 py-3 border-b border-[#EEEEEE]">
                    <img src={Img1} />
                    <div className="">
                      <p className="text-[#89868D] font-medium text-xs mb-1">
                        Report created successfully
                      </p>
                      <p className="text-[#B3B3B3] font-normal text-[10px]">
                        29 July 2020 - 02:26 PM
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <Link to="">
                <div className="border-b border-[#EEEEEE] w-full">
                  <p className="text-[#CC4D1D] font-semibold text-xs px-3 py-4 text-center">
                    View all notifications
                  </p>
                </div>
              </Link>
            </div>
          </div>

          <Link to="/userprofile" className="ms-3 flex items-center gap-2">
            <img className="w-[40px] rounded-full h-[40px]" src={profilepic?profilepic:Avtar} />
            <div className="text-end">
              <p className="text-base font-bold text-[#111827]">{fullname}</p>
              <p className="text-xs font-normal text-[#8E8E8E]">
                {role?.toUpperCase()}
              </p>
            </div>
          </Link>
        </div>
      </div>

      {/* Mobile */}
      <div className="justify-between gap-2.5  items-center px-5 lg:px-10 py-3 bg-white  shadow mobile flex lg:hidden header-inner">
        <FaBarsStaggered
          onClick={() => setOpen(open === "none" ? "large" : "none")}
          className="text-2xl cursor-pointer"
        />
        {/* <div className="relative lg:w-[50%] xl:w-[25%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            className=" ps-9 search-bar  rounded-lg focus:outline-0 py-3 ps-3 pe-7  0px 1px 4px] text-base relative w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
            placeholder="Search..."
          />
        </div> */}
        <Link to="/userprofile" className="ms-3 flex items-center gap-2">
          <img className="w-[40px] rounded-full" src={profilepic} />
          <div className="text-end">
            <p className="text-base font-bold text-[#111827]">{fullname}</p>
            <p className="text-xs font-normal text-[#8E8E8E]">
              {role?.toUpperCase()}
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Header;
