import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import { USERS_UPDATE_PASSWORD } from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import Lorder from "../../../admin/helper/Lorder";

const ForgetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const adminProfileData = localStorageAuthUserData();
  const { detail } = adminProfileData;
  const { _id } = detail || {};
  const password = useRef({});
  password.current = watch("password", "");
  const [loadind, setLoading] = useState(false);

  const submitHndle = async (data) => {
    const { oldPassword, password, cmfpassword } = data;
    setLoading(true);
    let payload = {
      userId: _id,
      old_password: oldPassword,
      password: password,
      confirm_password: cmfpassword,
    };
    try {
      const response = await axiosInstance.patch(
        `${BASE_URL}${USERS_UPDATE_PASSWORD}`,
        payload
      );
      const responseMessage = response?.data?.message;
      if (response.status) {
        ToastHandle(responseMessage, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(
          (data) => {
            submitHndle(data);
          },
          (err) => {
            console.log(err, "ee");
          }
        )}
      >
        <div className="">
          <label for="fname" className="mb-1 inline-block">
            Old Password:
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            placeholder="Type your password here"
            {...register("oldPassword", { required: true })}
            className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.oldPassword?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>

        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            New Password:
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            placeholder="Type your password here"
            {...register("password", { required: true })}
            className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.password?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
        </div>

        <div className="mt-4">
          <label for="lname" className="mb-1 inline-block">
            Confim Password:
          </label>
          <input
            id="confirmpassword"
            name="confirmpassword"
            type="password"
            autocomplete="current-password"
            placeholder="Type your password here"
            {...register("cmfpassword", {
              required: true,
              validate: (value) =>
                value === password.current || "Password Doesn't Match",
            })}
            className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-1 outline-0 focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
          />
          {errors.cmfpassword?.type === "required" &&
            errorMessageShow(errorEndPoint?.THIS_FIELD_IS_REQUIRED)}
          {errors?.cmfpassword?.type === "validate" && (
            <>{errorMessageShow("Password Doesn't Match")}</>
          )}
        </div>
        <button className="mt-8 bg-[#CC4D1D] ms-auto block w-auto text-white hover:bg-emerald-600 active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
          {!loadind ? "Update" : <Lorder />}
        </button>
      </form>
    </div>
  );
};

export default ForgetPasswordForm;
