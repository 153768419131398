import React, { useEffect, useState } from "react";
import "./customModal.css";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../admin/helper/ErrorMessage";
import Multiselect from "multiselect-react-dropdown";
import { createEditType, imageFirstUrl } from "../../admin/helper/AuthUserData";
import { requiredStar } from "../commonFunction/CommonFunction";
import { LoaderButton } from "../commonFunction/Loader";
const CustomModal = ({
  show,
  hide,
  heading,
  fieldGroups,
  onSubmitHndle,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const inputValueEmpty = () => {
    fieldGroups.forEach((fieldAll) => {
      const { fields } = fieldAll;
      fields.forEach((fieldGroup) => {
        reset({ [fieldGroup?.name]: "" });
      });
    });
  };
  //  mutileSelected
  const [selectedValueEmpty, setSelectedValueEmpty] = useState({
    errorType: false,
  });
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelect = (selectedList) => {
    setSelectedValues([...selectedList?.map((item) => item)]);
    setSelectedValueEmpty({
      errorType: false,
    });
  };
  console.log(selectedValues, "selectedValuesselectedValues");

  const handleRemove = (removedItem) => {
    setSelectedValues([...removedItem.map((item) => item)]);
  };

  // Debugging log for selected values
  const instructorEditHndle = async (data) => {
    const selectedField = { selectedValues };
    let isEditMode = false;
    let isAddMode = false;
    fieldGroups.forEach((fieldAll) => {
      const { fields } = fieldAll;
      fields.forEach((fieldGroup) => {
        if (fieldGroup?.value !== undefined && fieldGroup?.value !== "") {
          isEditMode = true;
        } else if (
          fieldGroup?.value === undefined ||
          fieldGroup?.value === ""
        ) {
          isAddMode = true;
        }
      });
    });

    // Only call the API once based on the conditions
    if (isEditMode) {
      await onSubmitHndle(data, createEditType?.edit, selectedField);
      inputValueEmpty();
    } else if (isAddMode) {
      await onSubmitHndle(data, createEditType?.add, selectedField);
      inputValueEmpty();
    }
  };

  const modelCloseHndle = () => {
    hide();
    inputValueEmpty();
  };

  useEffect(() => {
    setSelectedValues([]);
  }, [show]);

  let email = "email";
  let tel = "tel";
  let text = "text";
  let file = "file";
  const validationAdd = (type) => {
    switch (type) {
      case email:
        return {
          pattern: {
            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message: "Invalid email address",
          },
        };
      case tel:
        return {
          pattern: {
            value: /^[0-9]+$/,
            message: "Only numbers are allowed",
          },
        };
      case text:
      default:
        return {
          maxLength: {
            value: 50,
            message: "Text cannot be longer than 50 characters",
          },
        };
    }
  };

  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">{heading}</h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => modelCloseHndle()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">
                    {/* dynamic content */}
                    {fieldGroups &&
                      fieldGroups?.map((group, index) => {
                        return (
                          <div
                            key={index}
                            className={`grid ${group.layout} gap-5`}
                          >
                            {group?.fields.map((field, fieldIndex) => {
                              const errorForField = errors[field.name];
                              return (
                                <div
                                  key={fieldIndex}
                                  className={`mb-5 ${
                                    index === 0 && fieldIndex % 2 === 1
                                      ? "lg:mb-0"
                                      : ""
                                  }`}
                                >
                                  <label
                                    htmlFor={field.id}
                                    className="block mb-2 text-sm font-normal text-[#727272]"
                                  >
                                    {field.label} {requiredStar}
                                  </label>
                                  {field.type === "textarea" ? (
                                    <>
                                      <textarea
                                        id={field.id}
                                        name={field.name}
                                        defaultValue={field.value}
                                        placeholder={field.placeholder}
                                        {...register(`${field?.name}`, {
                                          required: true,
                                        })}
                                        className="border border-[#D9D9D9] p-3 h-[100px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                                      />
                                      {errorForField?.type === "required" &&
                                        errorMessageShow(
                                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                        )}
                                    </>
                                  ) : field.type === "select" ? (
                                    <>
                                      <Multiselect
                                        onKeyPressFn={function noRefCheck() {}}
                                        onSearch={function noRefCheck() {}}
                                        onRemove={handleRemove}
                                        onSelect={handleSelect}
                                        displayValue="name"
                                        selectedValues={field?.value?.map(
                                          (seletedItem) => {
                                            if (seletedItem) {
                                              return {
                                                value:
                                                  seletedItem?._id !== undefined
                                                    ? seletedItem?._id
                                                    : seletedItem?.value,
                                                name: seletedItem?.name || "", // Ensure name is a string or empty string
                                              };
                                            }
                                            return {}; // Handle undefined item
                                          }
                                        )}
                                        options={field?.options?.map(
                                          (fieldLable) => {
                                            if (fieldLable) {
                                              return {
                                                value:
                                                  fieldLable?._id !== undefined
                                                    ? fieldLable?._id
                                                    : fieldLable?.value,
                                                name: fieldLable?.name || "", // Ensure name is a string or empty string
                                              };
                                            }
                                            return {}; // Handle undefined option
                                          }
                                        )}
                                      />

                                      {selectedValueEmpty?.errorType &&
                                        errorMessageShow(
                                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                        )}
                                    </>
                                  ) : field.type === "selectNormal" ? (
                                    <>
                                      <div>
                                        <select
                                          className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272] bg-white text-[#727272]"
                                          name="cars"
                                          id="cars"
                                          {...register(`${field?.name}`, {
                                            required: true,
                                          })}
                                          defaultValue={field?.value?.name}
                                        >
                                          <option
                                            value={
                                              field.value !== ""
                                                ? field?.value?._id
                                                : ""
                                            }
                                            hidden
                                          >
                                            {field.value !== ""
                                              ? field?.value?.name
                                              : "Please Select one Hospital"}
                                          </option>
                                          {field?.options?.map((optionItem) => {
                                            const { value, name } = optionItem;
                                            return (
                                              <option
                                                value={value}
                                                selected={
                                                  name === field?.value?.name
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      {errorForField?.type === "required" &&
                                        errorMessageShow(
                                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                        )}
                                    </>
                                  ) : (
                                    <>
                                      <input
                                        type={field.type}
                                        id={field.id}
                                        name={field?.name}
                                        {...register(`${field.name}`, {
                                          required:
                                            field.type === file
                                              ? field?.imageShow[0] !==
                                                undefined
                                                ? false
                                                : true
                                              : true,
                                          ...validationAdd(field.type),
                                        })}
                                        defaultValue={field.value}
                                        placeholder={field.placeholder}
                                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                                      />
                                      {field?.name === "duration" && (
                                        <div>Week</div>
                                      )}
                                      {errorForField?.type === "required" &&
                                        errorMessageShow(
                                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                        )}
                                      {errorForField?.type === "pattern" &&
                                        errorMessageShow(
                                          field.type === email
                                            ? errors?.email?.message
                                            : field?.type === tel
                                            ? "Only numbers are allowed"
                                            : field?.type === text
                                            ? "Text cannot be longer than 50 characters"
                                            : ""
                                        )}
                                    </>
                                  )}
                                  {field?.imageShow !== undefined && (
                                    <div>
                                      <img
                                        className="max-w-[200px] w-[100%] mt-3"
                                        src={`${imageFirstUrl}${field?.imageShow}`}
                                      ></img>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-5 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => modelCloseHndle()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit(
                      (data) => {
                        instructorEditHndle(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                        if (selectedValues?.length === 0) {
                          setSelectedValueEmpty({ errorType: true });
                          return null;
                        }
                      }
                    )}
                  >
                    {!loading ? "Save" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CustomModal;
