import React, { useState } from "react";
import ToastHandle from "../../../../helper/ToastMessage";
import { TableLorder } from "../../../../helper/Lorder";
import Pagination from "../../../../../Components/commonFunction/Pagination";

const EvaluationTable = ({ data, setShowModel, tableLoading, currentPage, totalPages, handlePageChange }) => {

  const [evaluationData, setEvaluationData] = useState({ userCourseId: "" });
  const ids = evaluationData?.userCourseId
    ? JSON?.parse(evaluationData?.userCourseId)
    : {};
  const manageEvaluationHndle = () => {
    setShowModel({
      rangeData: ids,
      rangeOpen: true,
    });
  };

  return (
    <>
      {!tableLoading ? (
        <div className="table-section table-responsive rounded-[15px] w-full bg-white">
          <table className="w-full h-full table-auto table-layout-fixed">
            <thead className="bg-[#d9d9d9] h-[60px]">
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Name</th>
                <th scope="col">Certification number</th>
                <th scope="col">Expiration date</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Course</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((evaluationItem, evaluationIndex) => {
                const {
                  certificationnumber,
                  course,
                  email,
                  expirationdate,
                  name,
                  phoneNumber,
                  _id,
                } = evaluationItem;
                return (
                  <tr className="h-[60px] border-b border-[#F4F5F9]">
                    <th
                      scope="row"
                      className="text-[#89868D] text-sm  font-normal px-5 py-3"
                    >
                      {evaluationIndex + 1}
                    </th>
                    <td>{name}</td>
                    <td>{certificationnumber}</td>
                    <td>{expirationdate}</td>
                    <td>{phoneNumber}</td>
                    <td>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setEvaluationData({ userCourseId: e.target.value });
                        }}
                      >
                        <option value="" selected hidden>
                          Please Select
                        </option>
                        {course?.map((courseItem) => {
                          let idGet = {
                            courseId: courseItem?.courseId,
                            userId: _id,
                          };
                          return (
                            <option
                              value={JSON.stringify(idGet)}
                              selected={ids?.userId === _id ? true : false}
                            >
                              {courseItem?.courseName}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td>
                      <button
                        className="text-sm text-white rounded-[5px] bg-[#01AF7B] py-1.5 px-2.5"
                        onClick={() => {
                          if (evaluationData.userCourseId !== "") {
                            if (ids?.userId === _id) {
                              manageEvaluationHndle();
                            } else {
                              ToastHandle(
                                "Please Select Correct Course",
                                "danger"
                              );
                            }
                          } else {
                            ToastHandle("Select Course", "danger");
                          }
                        }}
                      >
                        Manage Evaluations
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default EvaluationTable;
