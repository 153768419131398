import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct the import
import { localStorageAuthUserData } from "../admin/helper/AuthUserData";

const ProtectedRoute = ({ element }) => {
  const authData = localStorageAuthUserData();
  const token = authData?.token;
  return token ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
