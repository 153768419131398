import React, { useEffect, useState } from "react";
import Coursedetailimg from "../../../../../images/course.png";
import { useParams } from "react-router-dom";
import { COURSE_GET_DETAIL } from "../../../../helper/ApiEndPoint";
import axios from "axios";
import ToastHandle from "../../../../helper/ToastMessage";
import { TableLorder } from "../../../../helper/Lorder";
import { convertTo12HourFormat } from "../../../../helper/dateFormate";

const CourseManagementProfileView = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const courseDetailGetListApi = async (id) => {
    setTableLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${COURSE_GET_DETAIL}${id}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  const {
    name,
    description,
    start_date,
    end_date,
    duration,
    class_days,
    classtimeto,
    classtimefrom,
    location,
    timezone,
    monthly_price,
  } = data || {};

  useEffect(() => {
    if (id !== null) {
      courseDetailGetListApi(id);
    }
  }, [id]);
  return (
    <>
      {!tableLoading ? (
        <div className="bg-white rounded-[10px] shadow-lg p-5 pt-8 h-full overflow-y-auto">
          <h3 className="text-black font-medium text-3xl mb-2">
            Hospital : {name} (ICU)
          </h3>
          <p className="text-black font-normal text-sm leading-6">
            {description}
          </p>
          <div className="mt-8 flex flex-wrap gap-5 items-start">
            <div className="lg:w-[68%] w-full">
              <img
                className="w-full h-[546px] object-cover rounded-[10px]"
                src={Coursedetailimg}
              />
            </div>
            <div className="lg:w-[28%] w-full">
              <div className="bg-[#F3F3F3] rounded-[10px] p-5">
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Start Date
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {start_date}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">End Date</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {end_date}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Duration</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {duration} Weeks
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Class Days
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {class_days?.map((days) => {
                      return `${days}, `;
                    })}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Class Time
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {classtimefrom && convertTo12HourFormat(classtimefrom)} to
                    {classtimeto && convertTo12HourFormat(classtimeto)}
                  </p>
                </div>
                {/* <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Time Zone</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {timezone}
                  </p>
                </div> */}
                <div className="flex items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Monthly Price $
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    ${monthly_price}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Location</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {location}
                  </p>
                </div>
                {/* <div className="flex flex-wrap items-center justify-between mb-[30px]">
            <p className="text-[626263] text-sm font-normal">CE Hours</p>
            <p className="text-[#CC4D1D] text-sm font-normal">20 hours</p>
          </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TableLorder />
      )}
    </>
  );
};

export default CourseManagementProfileView;
