import CourseManagementList from "./courseManagementList/CourseManagementList";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./CourseManagement.css";
import CustomModal from "../../../Components/customModal";
import axios from "axios";
import {
  COURSE_ADD,
  COURSE_DELETE,
  COURSE_LIST,
  USERS_DELETE_USER,
} from "../../helper/ApiEndPoint";
import ToastHandle from "../../helper/ToastMessage";
import { createEditType } from "../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";
import CourseEditModel from "./courseManagementList/courseEditModel/CourseEditModel";

const CourseManagementIndex = () => {
  const [EditGetData, setEditGetData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editModel, setEditModel] = useState({ type: false });
  const [search, setSearch] = useState("");

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const filterData = SearchFilter(data, search);
  const courseGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}${COURSE_LIST}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };

  const fieldGroups = [
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "cname",
          name: "cname",
          label: "Course Name",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "sdate",
          name: "sdate",
          label: "Start Date",
          type: "date",
          placeholder: "",
        },
        {
          id: "edate",
          name: "edate",
          label: "End Date",
          type: "date",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "duration",
          name: "duration",
          label: "Duration",
          type: "text",
          placeholder: "",
        },
        {
          id: "cdays",
          name: "cdays",
          label: "Class Days",
          type: "select",
          options: [
            { value: "Monday", name: "Monday" },
            { value: "Tuesday", name: "Tuesday" },
            { value: "Wednesday", name: "Wednesday" },
            { value: "Tuesday", name: "Tuesday" },
            { value: "Friday", name: "Friday" },
            { value: "Saturday", name: "Saturday" },
            { value: "Sunday", name: "Sunday" },
            // Add more options here
          ],
        },
      ],
    },
    {
      layout: "md:grid-cols-2 grid-cols-1", // Two columns on medium screens, one column on small screens
      fields: [
        {
          id: "time",
          name: "time",
          label: "Class Time",
          type: "time",
          placeholder: "",
        },
        {
          id: "tzone",
          name: "tzone",
          label: "End Time",
          type: "time",
          placeholder: "",
        },
      ],
    },

    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "location",
          name: "location",
          label: "Location",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "price",
          name: "price",
          label: "Monthly Price $",
          type: "text",
          placeholder: "",
        },
      ],
    },
    {
      layout: "md:grid-cols-1 grid-cols-1", // Single column layout for this group
      fields: [
        {
          id: "description",
          name: "description",
          label: "Short Description",
          type: "textarea",
          placeholder: "",
        },
      ],
    },
  ];
  const [loading, setLoading] = useState(false);
  const courseManagementAddApi = async (data, type, otherData) => {
    if (type === createEditType?.add) {
      setLoading(true);
      const { selectedValues } = otherData;
      const {
        cname,
        description,
        duration,
        edate,
        location,
        price,
        sdate,
        time,
        tzone,
        cdays,
      } = data;
      const body = {
        name: cname,
        start_date: sdate,
        end_date: edate,
        duration: duration,
        class_days: selectedValues?.map((days) => {
          return days?.value;
        }),
        join_date: "2024-09-09",
        classtimefrom: time,
        classtimeto: tzone,
        timezone: "America/New_York",
        location: location,
        monthly_price: price,
        description: description,
      };
      try {
        const response = await axiosInstance.post(
          `${BASE_URL}${COURSE_ADD}`,
          body
        );
        const responseMessage = response?.data?.message;
        if (response.status) {
          ToastHandle(responseMessage, "success");
          setShowModal(false);
          courseGetListApi();
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          ToastHandle(error?.response?.data?.message, "danger");
        }
      }
      setLoading(false);
    }
  };

  // delete course api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const courseDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${COURSE_DELETE}${id}`
      );
      if (response.status) {
        courseGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete course api integration
  useEffect(() => {
    courseGetListApi();
  }, []);
  return (
    <div className="course-mangement overflow-y-auto">
      {/* Top filter */}
      <div className="filter mt-5 bg-white rounded-[10px] custom-shadow flex flex-wrap justify-between h-[75px]  px-5 items-center lg:mb-10 mb-5">
        <div className="relative lg:w-[50%] xl:w-[20%] relative">
          <CiSearch className="text-xl absolute z-50 top-[13px] left-[11px]" />
          <input
            type="search"
            onChange={(e) => setSearch(e.target.value)}
            className="ps-9 search-bar border border-[#F0F0F0] rounded-lg focus:outline-0 py-2 pr-3 text-base w-full focus:ring-1 focus:ring-inset focus:ring-[#CC4D1D] font-normal placeholder:text-[14px] placeholder:text-[#8E8E8E]"
            placeholder="Search..."
          />
        </div>
        <div className="flex flex-wrap gap-5 sm:mt-0 mt-3">
          {/* <div className="dropdown inline-block relative">
            <button className=" text-[#CC4D1D] font-normal text-sm rounded-[5px]  py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0">
              All Courses
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
              </svg>
            </button>
            <ul className="shadow-xl dropdown-menu absolute top-[40px] left-[0px] hidden text-gray-700 pt-1 w-[150px]">
              <li className="">
                <Link
                  to="/"
                  className="rounded-t bg-white hover:bg-[#CC4D1D] hover:text-white text-[#CC4D1D] py-2 px-4 block whitespace-no-wrap"
                >
                  Lorem ipsum
                </Link>
              </li>
              <li className="">
                <Link
                  to="/"
                  className="bg-white hover:bg-[#CC4D1D] hover:text-white py-2 px-4  text-[#CC4D1D] block whitespace-no-wrap"
                >
                  Lorem ipsum
                </Link>
              </li>
            </ul>
          </div> */}
          <button
            onClick={() => setShowModal(true)}
            className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
          >
            <FiPlus className="text-white text-sm me-1" />
            Add New Course
          </button>
        </div>
      </div>
      {/* Top filter */}
      {/* Course body */}
      <CourseManagementList
        data={filterData}
        deleteHndle={(id) => courseDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
        modelModel={(data) => {
          setEditModel({ editData: data, type: true });
          // setShowModal(true);
        }}
      />
      {/* Course body */}
      <CustomModal
        heading="Add New Course"
        show={showModal}
        hide={() => setShowModal(false)}
        fieldGroups={fieldGroups}
        onSubmitHndle={(data, type, otherData) =>
          courseManagementAddApi(data, type, otherData)
        }
        loading={loading}
      />
      <CourseEditModel
        show={editModel}
        hide={() => {
          setEditModel({ type: false });
        }}
        updateAfterRes={courseGetListApi}
      />
    </div>
  );
};

export default CourseManagementIndex;
