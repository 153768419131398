import { imageFirstUrl } from "../../admin/helper/AuthUserData";
export const profileInterFaceType = { emsBoard: "emsBoard",hospitalM:"hospitalM" };
export const requiredStar=<span className="text-[red]">*</span>

export const imageFrame = (data,type) => {
  return (
    <div
      className={`flex items-center py-3  gap-3  ${type==="large"?"max-w-[280px] w-[200%]":"max-w-[100px] w-[100%]"} `}
    >
      <img src={`${imageFirstUrl}${data}`} alt="student" />
    </div>
  );
};

export const DataEmpty = () => {
  return (
    <div className="text-center">
      <p className="p-20  text-[red] ">No Data</p>
    </div>
  );
};



