export const USERS_LOGIN = "/users/login";
export const USERS_REGISTER = "/users/register";
export const USERS_INSTRUCTOR_LIST = "/users/instructorlist";
export const USERS_STUDENT_LIST = "/users/studentlist";
export const USERS_UPDATE_USER = "/users/updateuser";
export const USERS_DELETE_USER = "/users/deleteuser/";
export const USERS_FORGOT_PASSWORD = "/users/forgotpassword";
export const USERS_VERIFY_USER = "/users/verifyuser";
export const COURSE_ADD = "/course/add";
export const COURSE_UPDATE = "/course/update";
export const COURSE_LIST = "/course/list";
export const COURSE_GET_DETAIL = "/course/getdetail/";
export const COURSE_DELETE = "/course/delete/";
export const COURSE_GET_EVALUATION = "/course/getevaluation";
export const COURSE_ADD_EVALUATION = "/course/addevaluation";
export const COURSE_EDIT_EVALUATION = "/course/editevaluation";
export const COURSE_DELETE_EVALUATION = "/course/deleteevaluation/";
export const COURSE_GET_STUDENT_EVALUATION_LIST =
  "/course/getstudentevaluationlist";
export const COURSE_GET_COURSE_TO_ASSIGN = "/users/getcoursetoassign/";
export const USERS_GET_INSTRUCTOR_DETAIL = "/users/getinstructordetail/";
export const USERS_GET_INSTRUCTOR_ASSIGN_COURSE =
  "/users/getinstructorassigncourse/";
export const USERS_ADD_HOSPITAL = "/users/addhospital";
export const USERS_GET_HOSPITAL = "/users/gethospital";
export const USERS_GET_HOSPITAL_DETAILS = "/users/gethospitaldetail/";
export const USERS_UPDATE_HOSPITAL = "/users/updatehospital";
export const USERS_DELETE_HOSPITAL = "/users/deletehospital/";
export const USERS_ADD_EMS = "/users/addems";
export const USERS_GET_EMS = "/users/getems";
export const USERS_GET_EMS_DETAILS = "/users/getemsdetail/";
export const USERS_UPDATE_EMS = "/users/updateems";
export const USERS_DELETE_EMS = "/users/deleteems/";
export const USERS_ADD_AGENCY = "/users/addagency";
export const USERS_UPDATE_AGENCY = "/users/updateagency";
export const USERS_GET_AGENCY = "/users/getagency";
export const USERS_DELETE_AGENCY = "/users/deleteagency/";
export const USERS_ASSIGN_COURSE = "/course/assigncourse";
export const COURSE_DELETE_ASSIGN_COURSE = "/course/deleteassigncourse/";
export const COURSE_GET_STUDENT_EVALUATION = "/course/getstudentevaluation/";
export const COURSE_MANAGEE_VALUATION = "/course/manageevaluation";
export const USERS_USER_UPDATE = "/users/userupdate";
export const USERS_UPDATE_PASSWORD = "/users/update-password";
export const USERS_GET_PROFILE = "/users/get-profile/";
export const USERS_ENROLL = "/users/enroll";
export const USERS_COURSE_LIST = "/users/courses/";
export const INSTRUCTOR_COURSES_LIST = "/instructor/courses/";
export const INSTRUCTOR_STUDNETS_LIST = "/instructor/students/";
export const INSTRUCTOR_DASHBOARD_DATA = "/instructor/dashboard/";
export const INSTRUCTOR_STUDENT_PROFILE = "/instructor/student/";
export const INSTRUCTOR_EVALUATION_GET = "/instructor/evaluation/";
export const INSTRUCTOR_CERTIFICATION_CREATE =
  "/instructor/certification/create";
export const INSTRUCTOR_CERTIFICATION_UPDATE =
  "/instructor/certification/update/";
export const INSTRUCTOR_CERTIFICATION_STUDENT_LIST =
  "/instructor/certification/instructor/";
export const INSTRUCTOR_CERTIFICATE_DETAILS = "/instructor/certification/";
export const CERTIFICATION_VERFIY_STATUS =
  "/instructor/certification/verfiystatus/";
export const COURSE_GET_EVALUATION_BYCOURESEID = "/course/evaluations/";
export const COURSE_MANAGEE_EVALUATION_ADD = "/courseEvolution/add";
export const USERS_GET_STUDENT_CERTIFICATES = "/users/getstudentcertificates/";
export const COURSE_PAYMENT = "/users/course-payment";
export const COURSE_PAYMENT_SUCCESS = "/users/course-payment-success";
export const USERS_STUDENT_DASHBOARD_DATA = "/users/student-dashboard-data";
