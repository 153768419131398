import React, { useState } from "react";
import FilterBar from "../../../Components/FilterBar";
import AgencyManagementTable from "./agencyManagementTable/AgencyManagementTable";

const AgencyManagementIndex = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [showModel, setShowModel] = useState(false);
  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "New Students";

  return (
    <div className="student-managet overflow-y-auto">
      <FilterBar
        onSearch={handleSearch}
        dropdownOptions={dropdownOptions}
        onDropdownSelect={handleDropdownSelect}
        DropdownName={DropdownName}
        showBtn={true}
        showBtnText="Agency Management"
        showModal={() => setShowModel(true)}
      />
      <AgencyManagementTable
        agencyManagementOpen={showModel}
        agencyManagementClose={(type) => setShowModel(type)}
        searchTerm={searchTerm}
      />
    </div>
  );
};

export default AgencyManagementIndex;
