import React, { useEffect, useState } from "react";
import Coursedetailimg from "../../../../../images/course.png";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance, {
  BASE_URL,
} from "../../../../../Components/apiHeader/axiosInstance";
import {
  COURSE_GET_DETAIL,
  USERS_DELETE_USER,
} from "../../../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../../../admin/helper/ToastMessage";
import Table from "../../../../../Components/Table";
import SearchFilter from "../../../../../Components/commonFunction/SearchFilter";
import Studentimg from "../../../../../images/student.png";

const InsturctorCourseManagementProfileView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const courseDetailGetListApi = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}${COURSE_GET_DETAIL}${id}`
      );
      if (response.status) {
        setData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };
  const {
    start_date,
    end_date,
    duration,
    class_days,
    classtimeto,
    classtimefrom,
    location,
    timezone,
    monthly_price,
    description,
    name,
    enrolledStudents,
  } = data || {};
  //table
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Studentimg} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification Number", field: "certificationNumber" },
    { header: "Expiration Date", field: "expirationDate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },
    // {
    //   header: "Status",
    //   field: "status",
    //   render: (status) => (
    //     <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
    //       {status}
    //     </button>
    //   ),
    // },
  ];
  const convertData = enrolledStudents?.map((instruct) => {
    const {
      _id,
      role,
      phone,
      fullname,
      expiry_date,
      email,
      certification_number,
      agency,
      address,
      is_verify,
    } = instruct;
    return {
      _id,
      role,
      agency,
      address,
      name: fullname,
      certificationNumber: certification_number,
      expirationDate: expiry_date,
      email: email,
      phoneNumber: phone,
      status: is_verify ? "verify" : "Not verify",
    };
  });

  let filterData = SearchFilter(convertData, "");

  //table
  // delete student api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const studentDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_USER}${id}`
      );
      if (response.status) {
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };
  // delete student api integration

  useEffect(() => {
    if (id !== null) {
      courseDetailGetListApi(id);
    }
  }, [id]);

  return (
    <>
      <div className="bg-white rounded-[10px] shadow-lg p-5 pt-8 h-full overflow-y-auto">
        <div className="mb-[18px]">
          <h3 className="text-black font-medium text-3xl mb-2">
            Hospital : {name} (ICU)
          </h3>
          <p className="text-black font-normal text-sm leading-6">
            {description}
          </p>
          <div className="mt-8 flex flex-wrap gap-5 items-start">
            <div className="lg:w-[68%] w-full">
              <img
                className="w-full h-[496px] object-cover rounded-[10px]"
                src={Coursedetailimg}
              />
            </div>
            <div className="lg:w-[28%] w-full">
              <div className="bg-[#F3F3F3] rounded-[10px] p-5">
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Start Date
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {start_date}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">End Date</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {end_date}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Duration</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {duration}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Class Days
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {class_days?.map((days) => {
                      return `${days}, `;
                    })}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Class Time
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {classtimeto} to {classtimefrom}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Time Zone</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {timezone}
                  </p>
                </div>
                <div className="flex items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">
                    Monthly Price $
                  </p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    ${monthly_price}
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-between mb-[30px]">
                  <p className="text-[626263] text-sm font-normal">Location</p>
                  <p className="text-[#CC4D1D] text-sm font-normal">
                    {location}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          notShowing={true}
          columns={columns}
          data={filterData}
          // modelModel={(data) => setEditGetData(data)}
          navigateHndle={(id) => navigate(`/Student-profile/${id}`)}
          // verifyHndle={(id) => studentVerifyApiHndle(id)}
          deleteHndle={(id) => studentDeleteApiHndle(id)}
          deleteLoading={deleteLoading}
          DeleteModelClose={DeleteModelClose}
          setDeleteModelClose={setDeleteModelClose}
          // tableLoading={tableLoading}
        />
      </div>
    </>
  );
};

export default InsturctorCourseManagementProfileView;
