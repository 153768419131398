import React, { useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import Instructorimg from "../../../../images/instructor-profile.png";
import CustomModal from "../../../../Components/customModal";
import { useNavigate } from "react-router-dom";
import {
  USERS_DELETE_USER,
  USERS_INSTRUCTOR_LIST,
  USERS_UPDATE_USER,
  USERS_VERIFY_USER,
} from "../../../helper/ApiEndPoint";
import ToastHandle from "../../../helper/ToastMessage";
import axios from "axios";
import { createEditType, limitData } from "../../../helper/AuthUserData";
import axiosInstance, {
  BASE_URL,
} from "../../../../Components/apiHeader/axiosInstance";
import SearchFilter from "../../../../Components/commonFunction/SearchFilter";
import Pagination from "../../../../Components/commonFunction/Pagination";

const InstructorManagementTable = ({ searchTerm }) => {
  const [showModal, setShowModal] = useState(false);

  const [editGetData, setEditGetData] = useState("");
  const navigate = useNavigate();
  // table
  const columns = [
    {
      header: "Name",
      field: "name",
      render: (data) => (
        <div className="flex items-center py-3 ps-3 gap-3">
          <img src={Instructorimg} alt="student" />
          <p>{data}</p>
        </div>
      ),
    },
    { header: "Certification Number", field: "certificationNumber" },
    { header: "Expiration Date", field: "expirationDate" },
    { header: "Email", field: "email" },
    { header: "Phone Number", field: "phoneNumber" },
    {
      header: "Status",
      field: "status",
      render: (status) => (
        <button className="text-[#CC4D1D] font-normal text-sm rounded-[5px] py-2 px-3 border border-[#CC4D1D]">
          {status}
        </button>
      ),
    },
  ];

  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const filterData = SearchFilter(data, searchTerm);
  const instructorGetListApi = async () => {
    setTableLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${USERS_INSTRUCTOR_LIST}?page=${currentPage}&limit=${limitData}`
      );
      if (response.status) {
        const instructorGetData = response?.data?.detail;
        const convertData = instructorGetData?.map((instruct) => {
          const {
            _id,
            role,
            phone,
            fullname,
            expiry_date,
            email,
            certification_number,
            agency,
            address,
            is_verify,
          } = instruct;
          return {
            _id,
            role,
            agency,
            address,
            name: fullname,
            certificationNumber: certification_number,
            expirationDate: expiry_date,
            email: email,
            phoneNumber: phone,
            status: is_verify ? "verify" : "Not verify",
          };
        });
        setData(convertData);
        setCurrentPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setTableLoading(false);
  };
  // table

  // input field
  const [fieldGroups, setFieldGroups] = useState("");
  const {
    _id,
    name,
    email,
    expirationDate,
    phoneNumber,
    address,
    certificationNumber,
    lname,
    description,
  } = editGetData;
  useEffect(() => {
    if (editGetData !== "") {
      setShowModal(true);

      setFieldGroups([
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "fname",
              name: "fname",
              label: "First name",
              type: "text",
              value: name || "",
              placeholder: "",
            },
            // {
            //   id: "lname",
            //   name: "lname",
            //   label: "Last name",
            //   type: "text",
            //   value: lname || "",
            //   placeholder: "",
            // },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "cername",
              name: "cername",
              label: "Certification Number",
              type: "text",
              value: certificationNumber || "",
              placeholder: "",
            },
            {
              id: "exdate",
              name: "exdate",
              label: "Expiration Date",
              type: "date",
              value: expirationDate || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-2 grid-cols-1",
          fields: [
            {
              id: "email",
              name: "email",
              label: "E-Mail",
              type: "email",
              value: email || "",
              placeholder: "",
            },
            {
              id: "phone",
              name: "phone",
              label: "Telephone Number",
              type: "tel",
              value: phoneNumber || "",
              placeholder: "",
              pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "address",
              name: "address",
              label: "Address",
              type: "text",
              value: address || "",
              placeholder: "",
            },
          ],
        },
        {
          layout: "md:grid-cols-1 grid-cols-1",
          fields: [
            {
              id: "description",
              name: "description",
              label: "Short Description",
              type: "textarea",
              value: description || "",
              placeholder: "",
            },
          ],
        },
      ]);
    }
  }, [editGetData]);
  // input field

  // verify instructor api integration
  const instructorVerifyApiHndle = async (id) => {
    const body = {
      userId: id,
      status: true,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${USERS_VERIFY_USER}`,
        body
      );
      if (response.status) {
        instructorGetListApi();
        ToastHandle(response?.data?.message, "success");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
  };

  // // instructor edit
  const [loading, setLoading] = useState(false);
  const editInstructorApiHndle = async (data, type) => {
    setLoading(true);
    const {
      address,
      cername,
      description,
      email,
      exdate,
      fname,
      lname,
      phone,
    } = data;
    const body = {
      userId: _id,
      fullname: fname,
      email: email,
      certification_number: cername,
      expiry_date: exdate,
      join_date: "2024-01-01",
      phone: phone,
      address: address,
      agency: "Agency Name",
      description: description,
    };
    try {
      const response = await axiosInstance.post(
        `${BASE_URL}${type === createEditType?.edit && USERS_UPDATE_USER}`,
        body
      );
      if (response.status) {
        instructorGetListApi();
        setShowModal(false);
        ToastHandle(response?.data?.message, "success");
        setFieldGroups("");
        setEditGetData("");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };
  // // evaluation create and edit

  // delete student api integration
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DeleteModelClose, setDeleteModelClose] = useState(false);
  const instructorDeleteApiHndle = async (id) => {
    try {
      setDeleteLoading(true);
      const response = await axiosInstance.delete(
        `${BASE_URL}${USERS_DELETE_USER}${id}`
      );
      if (response.status) {
        instructorGetListApi();
        setDeleteModelClose(true);
        setDeleteLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
        setDeleteLoading(false);
      }
    }
  };

  // delete student api integration
  useEffect(() => {
    instructorGetListApi();
  }, [currentPage]);

  return (
    <div>
      <Table
        columns={columns}
        data={filterData}
        modelModel={(data) => {
          setEditGetData(data);
        }}
        navigateHndle={(id) => navigate(`/instructor-profile/${id}`)}
        verifyHndle={(id) => instructorVerifyApiHndle(id)}
        deleteHndle={(id) => instructorDeleteApiHndle(id)}
        deleteLoading={deleteLoading}
        DeleteModelClose={DeleteModelClose}
        setDeleteModelClose={setDeleteModelClose}
        tableLoading={tableLoading}
      />
          <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      <CustomModal
        heading="Edit Instructor"
        show={showModal}
        hide={() => {
          setShowModal(false);
          setEditGetData("");
          setFieldGroups("");
        }}
        fieldGroups={fieldGroups}
        onSubmitHndle={(data, type) => editInstructorApiHndle(data, type)}
        loading={loading}
      />
    </div>
  );
};

export default InstructorManagementTable;
