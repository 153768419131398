export function formatDate(dateStr) {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
}
export const formatDateTwoType = (isoDate) => {
  const date = new Date(isoDate);
  const options = { year: 'numeric', month: 'long' };  // This will return "July 2024"
  return date.toLocaleDateString('en-US', options);
};


export function formatDateForm(dateStr) {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function convertTo12HourFormat(time24) {
  const [hours, minutes] = time24.split(":").map(Number);
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12; // Convert to 12-hour format
  return `${hours12}:${String(minutes).padStart(2, "0")} ${ampm}`;
}