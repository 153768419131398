import React, { useState } from "react";
import StudentManagementTable from "./StudentManagementTable/StudentManagementTable";
import FilterBar from "../../../Components/FilterBar";

const StudentManagementIndex = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const dropdownOptions = ["Ram", "Sham", "Ali", "John"];

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDropdownSelect = (option) => {
    setSelectedDropdownOption(option);
  };
  const DropdownName = "New Students";
  return (
    <div>
      <div className="student-managet overflow-y-auto">
        <FilterBar
          onSearch={handleSearch}
          dropdownOptions={dropdownOptions}
          onDropdownSelect={handleDropdownSelect}
          DropdownName={DropdownName}
        />
        <StudentManagementTable searchTerm={searchTerm}/>
      </div>
    </div>
  );
};

export default StudentManagementIndex;
